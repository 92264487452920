import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography, Pagination, Link, Button, Menu, MenuItem, Box, IconButton, Stack } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { BeatLoader } from "react-spinners";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import { tofieds } from "functions/Functions";

const override = {
     display: "flex",
     justifyContent: 'center',
     alignItems: 'center',
};

const GridTableSaleList = (props) => {

     const classes = useStyles();
     const [anchorEl, setAnchorEl] = React.useState(null);
     const [id, setId] = React.useState(null);
     const [name, setName] = React.useState(null);
     const [lastname, setLastname] = React.useState(null);
     const history = useHistory();

     const [show, setShow] = useState({});

     const handleShow = (id) => {
          setShow((prevState) => ({
               ...prevState,
               [id]: !prevState[id], // สลับสถานะการแสดงข้อมูลของแถวที่คลิก
          }));
     };


     const handleClickMenu = (event, id, name, lastname) => {
          setAnchorEl(event.currentTarget);
          setId(id);
          setName(name);
          setLastname(lastname);
     };

     const handleCloseMenu = () => {
          setAnchorEl(null);
     };

     const openPort = (id, name, lastname) => {
          history.push({
               pathname: `/sale/port/${id}`,
               state: { username: `${name} ${lastname}` },
          });
     };

     useEffect(() => {
          if (props.showAll) {
               const allShow = props.body.reduce((acc, el) => {
                    acc[el.user_id] = true;
                    return acc;
               }, {});
               setShow(allShow);
          } else {
               setShow({});
          }
     }, [props.showAll]);

     useEffect(() => {
          if (props.search_check) {
               setShow({});
          }
     }, [props.search_check]);

     //-------------------------------------------------//
     return (
          <Grid className="table-btn">
               <TableContainer>
                    <Table className={classes.table}>
                         <TableHead>
                              <TableRow>
                                   {props.head.map((el, i) => {
                                        return (
                                             <TableCell key={i} align={el.align || "left"}>
                                                  {el.label === "Agent Fee" ?
                                                       <Stack direction="row" width="100%" display="flex" alignItems="center" justifyContent="center" spacing={3}>
                                                            <Typography noWrap>{el.label}</Typography>
                                                            <Grid>
                                                                 {props.showAll ?
                                                                      <IconButton
                                                                           onClick={() => {
                                                                                props.handleShowAll();
                                                                                props.getUserInSale();
                                                                           }}
                                                                      >
                                                                           <CancelPresentationIcon fontSize="large" />
                                                                      </IconButton>
                                                                      :
                                                                      <IconButton
                                                                           onClick={() => {
                                                                                props.handleShowAll();
                                                                                props.getUserSaleAllAgent();
                                                                           }}
                                                                      >
                                                                           <SettingsOverscanIcon fontSize="large" />
                                                                      </IconButton>
                                                                 }
                                                            </Grid>
                                                       </Stack>
                                                       :
                                                       <Typography noWrap>{el.label}</Typography>
                                                  }
                                                  {/* <Typography noWrap>{el.label}</Typography> */}
                                             </TableCell>
                                        );
                                   })}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {props.body.map((el, i) => {
                                   return (
                                        <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.user_id}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.name} {el.lastname}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.email}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="center">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.phone}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.deposit}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.withdraw}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.net_deposit}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="center">
                                                  <Stack width="100%" spacing={1} display="flex" justifyContent="center" alignItems="center">
                                                       <Grid>
                                                            {show[el.user_id] ?
                                                                 <Button onClick={() => {
                                                                      handleShow(el.user_id);
                                                                 }}>
                                                                      {props.language === "th" ? "ซ่อนข้อมูล" : "Hide"}
                                                                 </Button>
                                                                 :
                                                                 <Button onClick={() => {
                                                                      props.getSaleAgentBySale(el.user_id);
                                                                      handleShow(el.user_id);
                                                                 }}>
                                                                      {props.language === "th" ? "แสดงข้อมูล" : "Show"}
                                                                 </Button>
                                                            }
                                                       </Grid>
                                                       {show[el.user_id] &&
                                                            <Grid>
                                                                 {el.agent_fee !== undefined ?
                                                                      <Typography noWrap sx={{ fontSize: 16, color: "#0d47a1" }}>
                                                                           {tofieds(el.agent_fee) || "0.00"}
                                                                      </Typography>
                                                                      :
                                                                      <Box display="flex" justifyContent="center" alignItems="center">
                                                                           <BeatLoader
                                                                                color="#0d47a1"
                                                                                loading={el.agent_fee !== undefined ? false : true}
                                                                                cssOverride={override}
                                                                                size={5}
                                                                                aria-label="Loading Spinner"
                                                                                data-testid="loader"
                                                                           />
                                                                      </Box>
                                                                 }
                                                            </Grid>
                                                       }
                                                  </Stack>
                                             </TableCell>
                                             {/* <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.agent_fee}
                                                  </Typography>
                                             </TableCell> */}
                                             {/* <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.sumLot}
                                                  </Typography>
                                             </TableCell> */}
                                             <TableCell align="center">
                                                  {el.verify_doc === 1 ? (
                                                       <Typography noWrap className={classes.fontActive} sx={{ backgroundColor: "#82C729" }}>
                                                            Active
                                                       </Typography>
                                                  ) : el.verify_doc === 2 ? (
                                                       <Typography noWrap className={classes.fontActive} sx={{ backgroundColor: "#FF0000" }}>
                                                            Unverify
                                                       </Typography>
                                                  ) : el.verify_doc === 3 ? (
                                                       <Typography noWrap className={classes.fontActive} sx={{ backgroundColor: "red" }}>
                                                            Unsuccess
                                                       </Typography>
                                                  ) : el.verify_doc === 4 ? (
                                                       <Typography noWrap className={classes.fontActive} sx={{ backgroundColor: "#002BB7" }}>
                                                            Pending
                                                       </Typography>
                                                  ) : (
                                                       "-"
                                                  )}
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.ib_status === 1 ? "YES" : "NO"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="center">
                                                  <Link
                                                       component="button"
                                                       sx={{ fontSize: 16, color: "#184B8E" }}
                                                       onClick={() => {
                                                            openPort(el.user_id, el.name, el.lastname);
                                                       }}
                                                  >
                                                       <MoreVertIcon />
                                                  </Link>
                                                  {/* <Grid item container justifyContent={"flex-start"}>
                                                       <Button aria-controls="simple-menu" aria-haspopup="true"
                                                            onClick={(event) => handleClickMenu(event, el.user_id, el.name, el.lastname)}
                                                       >
                                                            <MoreVertIcon />
                                                       </Button>
                                                       <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                                            <MenuItem
                                                                 onClick={() => {
                                                                      handleCloseMenu();
                                                                      openPort(id, name, lastname);
                                                                 }}
                                                            >
                                                                 พอร์ตลูกค้า
                                                            </MenuItem>
                                                       </Menu>
                                                  </Grid> */}
                                             </TableCell>
                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               <Grid container justifyContent="center" mt={5}>
                    {props.body.length === 0 && (
                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                              {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                         </Typography>
                    )}
                    {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
     fontActive: {
          fontSize: 15,
          color: "#fff",
          padding: 8,
          borderRadius: 10,
     },
});

export default GridTableSaleList;
