import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography, Pagination, Button } from "@material-ui/core";
import { withRouter, useHistory } from "react-router-dom";
import moment from "moment";

const GridTable = (props) => {
     const classes = useStyles();
     const history = useHistory();

     const onClickNetwork = (id) => {
          history.push(`/network_user/${id}`);
     };
     //-------------------------------------------------//
     return (
          <Grid className="table-btn">
               <TableContainer>
                    <Table className={classes.table}>
                         <TableHead>
                              <TableRow>
                                   {props.head.map((el, i) => {
                                        return (
                                             <TableCell key={i} align={el.align || "left"}>
                                                  <Typography noWrap>{el.label}</Typography>
                                             </TableCell>
                                        );
                                   })}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {props.body.map((el, i) => {
                                   return (
                                        <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {`${el.name} ${el.lastname}` || "-"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.phone}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.email}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {moment(el.created_at).format("DD/MM/YYYY")}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.ib_status === 2 && "ยังไม่สมัคร IB"}
                                                       {(el.ib_status === 1 && el.is_ib === 1) && "เป็น IB"}
                                                       {(el.ib_status === 1 && el.is_ib !== 1) && "เป็น SUB IB"}
                                                       {el.ib_status === 4 && "รอการยืนยัน"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.admin_name} {el.admin_lastname}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Button
                                                       variant="contained"
                                                       // color="error"
                                                       style={{ backgroundColor: "#4589C6" }}
                                                       sx={{ maxWidth: 200, fontSize: 12, width: "100%" }}
                                                       onClick={() => props.onClickChangeToManager(el.user_id)}
                                                  >
                                                       {props.language === "th" ? "เปลี่ยนเป็น IB" : "TO IB"}
                                                  </Button>
                                             </TableCell>
                                             {props.role !== "sale" && (
                                                  <TableCell>
                                                       <Button
                                                            variant="contained"
                                                            // color="error"
                                                            style={{ backgroundColor: "#4589C6" }}
                                                            sx={{ maxWidth: 110, fontSize: 12, width: "100%" }}
                                                            onClick={() => props.onClickOpen(el.user_id)}
                                                       >
                                                            {props.language === "th" ? "เลือก Sale" : "Select Sale"}
                                                       </Button>
                                                  </TableCell>
                                             )}
                                             {props.role === "sale" && (
                                                  <TableCell>
                                                       <Button
                                                            variant="contained"
                                                            // color="error"
                                                            style={{ backgroundColor: "#4589C6" }}
                                                            sx={{ maxWidth: 200, fontSize: 12, width: "100%" }}
                                                            onClick={() => props.onClickChangeToManager(el.user_id)}
                                                       >
                                                            {props.language === "th" ? "เปลี่ยนเป็น Manage" : "Change to manager"}
                                                       </Button>
                                                  </TableCell>
                                             )}
                                             <TableCell>
                                                  <Button
                                                       variant="contained"
                                                       color="primary"
                                                       sx={{ maxWidth: 110, fontSize: 12, width: "100%" }}
                                                       onClick={() => onClickNetwork(el.user_id)}
                                                  >
                                                       {props.language === "th" ? "Network" : "Network"}
                                                  </Button>
                                             </TableCell>
                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               <Grid container justifyContent="center" mt={5}>
                    {props.body.length === 0 && (
                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                              {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                         </Typography>
                    )}
                    {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
     button: {
          "&:hover": {
               backgroundColor: "none",
          },
     },
     fontActive: {
          fontSize: 15,
          color: "#fff",
          padding: 8,
          borderRadius: 10,
     },
});
export default withRouter(GridTable);
