import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Stack, Button, TextField, Input, Box } from "@material-ui/core";
import choose_img from "../../../assets/images/choose-image.png";
import DatePicker from "react-date-picker";
import { Editor } from "react-draft-wysiwyg";


const GridEdit = (props) => {
    //  const classes = useStyles();
    const { data, language, page, handleDateEndChange, handleDateStartChange, state, uploadImage, handleChangeText, handleChangeEditor, onClickEditPromotion } = props;
    const classes = useStyles();

    return (
        <Grid container columns={12} display="flex" justifyContent="center">
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>Promotion Edit</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} display="flex" justifyContent="center" >
                <Stack alignItems="center" spacing={2} mt={2}>
                    <img
                        alt="Image"
                        src={data.profile_image ? data.profile_image : choose_img}
                        width="auto"
                        height={300}
                    />
                    <label htmlFor="contained-button-file" style={{ width: 200 }}>
                        <Input
                            // accept="image/*"
                            accept="image/png, image/jpeg"
                            id="contained-button-file"
                            type="file"
                            onChange={uploadImage}
                        />
                        <Button variant="outlined" color="error" component="span" className="btn-style" sx={{ mt: 2, maxWidth: 200 }}>
                            {language === "th" ? "เลือกไฟล์" : "Choose File"}
                        </Button>
                    </label>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12} mt={3}>
                <Grid container columns={12} spacing={2}>
                    <Grid item xs={6} spacing={2}>
                        <Typography className={classes.fontStyleTitle}>
                            {language === "th" ? "วันที่เริ่มต้น" : "Start date"}
                        </Typography>
                        <DatePicker className={"date-picker"} onChange={handleDateStartChange} value={state.date_start} format="dd/MM/yyyy" />
                    </Grid>
                    <Grid item xs={6} spacing={2}>
                        <Typography className={classes.fontStyleTitle}>
                            {language === "th" ? "วันที่สิ้นสุด" : "End date"}
                        </Typography>
                        <DatePicker className={"date-picker"} onChange={handleDateEndChange} value={state.date_end} format="dd/MM/yyyy" />
                    </Grid>
                    <Grid item xs={12} spacing={2}>
                        <Typography className={classes.fontStyleTitle}>{language === "th" ? "ชื่อโปรโมชัน" : "Promotion name"}</Typography>
                        <TextField
                            fullWidth
                            size="small"
                            type="text"
                            name="promotion_name"
                            value={state.promotion_name}
                            onChange={handleChangeText}
                        />
                    </Grid>
                    <Grid item xs={12} spacing={2}>
                        <Typography className={classes.fontStyleTitle}>{language === "th" ? "รายละเอียด" : "Detail"}</Typography>
                        <Box className={classes.boxEditor}>
                            <Editor
                                defaultEditorState={state.editorState}
                                editorState={state.editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={handleChangeEditor}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "#4589C6" }}
                            // onClick={async () => {
                            //      await onClickAddDemo();
                            //      handleCloseModal();
                            // }}
                            // disabled={loading}
                            sx={{ m: 1, mt: 2, minWidth: 130 }}
                            onClick={onClickEditPromotion}
                        >
                            {language === "th" ? "แก้ไข" : "Edit"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    fontStyleTitle: {
        fontSize: "16px",
        fontWeight: 600,
        color: "#000",
        marginBottom: 2,
    },
    boxEditor: {
        borderStyle: "solid",
        borderColor: "#EFEFEF"
    }
}));
export default withRouter(GridEdit);

