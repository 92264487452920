import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Link } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { headerTable } from "../../../functions/Static";

const TableWithdrawal = (props) => {
     const classes = useStyles();
     return (
          <Grid item xs={12}>
               <Grid className="table-btn">
                    <TableContainer>
                         <Table className={classes.table}>
                              <TableHead>
                                   <TableRow>
                                        {headerTable.settingWithdrawal.map((el, i) => {
                                             return (
                                                  <TableCell key={i} align={el.align || "left"}>
                                                       <Typography noWrap>{el.label}</Typography>
                                                  </TableCell>
                                             );
                                        })}
                                   </TableRow>
                              </TableHead>
                              <TableBody>
                                   {props.data.map((el, i) => {
                                        return (
                                             <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                                  <TableCell>
                                                       <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                            {el.min}
                                                       </Typography>
                                                  </TableCell>
                                                  <TableCell>
                                                       <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                            {el.max}
                                                       </Typography>
                                                  </TableCell>
                                                  <TableCell>
                                                       <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                            {el.type}
                                                       </Typography>
                                                  </TableCell>
                                                  <TableCell>
                                                       <Link
                                                            component="button"
                                                            sx={{ fontSize: 16, color: "#184B8E" }}
                                                            onClick={() => {
                                                                 props.onClickOpenModal(el.withdraw_id, el.min, el.max);
                                                            }}
                                                       >
                                                            Edit
                                                       </Link>
                                                  </TableCell>
                                             </TableRow>
                                        );
                                   })}
                              </TableBody>
                         </Table>
                    </TableContainer>
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
});

export default withRouter(TableWithdrawal);
