import { Button, Grid } from "@material-ui/core";
import HeaderTypography from "components/HeaderTypography/HeaderTypography";
import Sidebar from "pages/Setting/Components/Sidebar";
import ModalEdit from "./ModalEdit";
import TableListAdmin from "./TableListAdmin";

const AdminCard = (props) => {
     const { language, data_admin, onClickAdd, onClickDelete, onClickOpen, open, onClickClose, onClickEdit, state, handleChangeText, page, handleChangePage, onClickBan, onClickUnbanAccount, handleOpenModal } = props;
     return (
          <Grid container width={"100%"} spacing={2}>
               <Grid item xs={3}>
                    <Sidebar title={"Add Admin"} pageName={"admin"} />
               </Grid>
               <Grid item xs={9} container spacing={2} alignContent={"start"}>
                    <Grid item xs={12}>
                         <HeaderTypography title={language === "th" ? "รายชื่อผู้ดูแล" : "Admin List"} />
                    </Grid>
                    <Grid item xs={12} container justifyContent={"end"}>
                         <Button
                              variant="contained"
                              style={{ backgroundColor: "#4589C6" }}
                              //  className="btn-style"
                              sx={{ maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, height: 50 }}
                              onClick={onClickAdd}
                         >
                              +{language === "th" ? "เพิ่มผู้ดูแล" : "Add Admin"}
                         </Button>
                    </Grid>
                    <Grid item xs={12}>
                         <TableListAdmin
                              body={data_admin}
                              onClickDelete={onClickDelete}
                              onClickOpen={onClickOpen}
                              page={page}
                              handleChangePage={handleChangePage}
                              onClickBan={onClickBan}
                              onClickUnbanAccount={onClickUnbanAccount}
                              handleOpenModal={handleOpenModal}
                         />
                    </Grid>
                    <ModalEdit open={open} onClickClose={onClickClose} onClickEdit={onClickEdit} state={state} handleChangeText={handleChangeText} />
               </Grid>
          </Grid>
     );
};

export default AdminCard;
