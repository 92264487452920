import { Container } from "@material-ui/core";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GETSALELOTBYADMINID, POST } from "service";
import swal from "sweetalert2";
import ManagerLotCard from "./components/ManagerLotCard";

export class ManagerLot extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: localStorage.getItem("language"),
            data: [],
            profile: JSON.parse(this.props.match.params.detail),
        };
    }
    componentDidMount = () => {
        this.getLotByAdminId();
    };

    getLotByAdminId = async () => {
        this.setState({ loading: true });
        const { language, profile } = this.state;
        try {
            let res = await POST(GETSALELOTBYADMINID, { lang: language, admin_id: profile.id, date_start: profile.date_start, date_end: profile.date_end });
            if (res.success) {
                this.setState({ data: res.result })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };

    render() {
        const { language, data, profile } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <ManagerLotCard
                    language={language}
                    data={data}
                    profile={profile}
                />
            </Container>
        );
    }
}

export default withRouter(ManagerLot);
