import { Container, Grid } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GETPORT, POST } from "service";
import swal from "sweetalert2";
import ListPort from "./components/ListPort";
export class Port extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            loading: false,
            data_port: [],
            profile: this.props.location.state.username
        };
    }

    componentDidMount = () => {
        this.getPort();
    }

    getPort = async () => {
        try {
            this.setState({ loading: true });
            let getPort = await POST(GETPORT, { user_id: this.props.match.params.id });
            if (getPort.success) {
                this.setState({ data_port: getPort.result });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                // swal.fire({ title: "Warning", text: getIB.message, icon: "warning", timer: 1800, showConfirmButton: false });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
        }
    };

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };

    render() {
        const language = localStorage.getItem("language");
        const { data_port, page, loading } = this.state;
        return (
            <Container maxWidth="xl">
                <Loading open={loading} />
                <Grid>
                    <ListPort
                        language={language}
                        data={data_port}
                        page={page}
                        handleChangePage={this.handleChangePage}
                        state={this.state}
                    />
                </Grid>
            </Container>
        );
    }
}

export default withRouter(Port);
