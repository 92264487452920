import { Grid, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import { withRouter } from "react-router-dom";

const GridTableTran = (props) => {
     const classes = useStyles();
     //-------------------------------------------------//
     return (
          <Grid className="table-btn">
               <TableContainer>
                    <Table className={classes.table}>
                         <TableHead>
                              <TableRow>
                                   {props.head.map((el, i) => {
                                        return (
                                             <TableCell key={i} align={el.align || "left"}>
                                                  <Typography noWrap>{el.label}</Typography>
                                             </TableCell>
                                        );
                                   })}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {props.body.map((el, i) => {
                                   return (
                                        <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {Number(el.transaction_id)}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.name} {el.lastname}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {moment(el.created_at).format("DD/MM/YYYY")}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.type}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.type === 'withdraw' ?
                                                            PAYMENT_TYPE_WITHDRAW[el.withdraw_type].label
                                                            :
                                                            PAYMENT_TYPE_DEPOSIT[el.payment_type].label
                                                       }
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.remark}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {Number(el.money).toFixed(6)}
                                                  </Typography>
                                             </TableCell>

                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               <Grid container justifyContent="center" mt={5}>
                    {props.body.length === 0 && (
                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                              {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                         </Typography>
                    )}
                    {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
});

const PAYMENT_TYPE_WITHDRAW = {
     1: { label: "Gateway" },
     2: { label: "Slip (โอนมือ)" },
     3: { label: "Crypto" },
};

const PAYMENT_TYPE_DEPOSIT = {
     0: { label: "PromptPay" },
     1: { label: "Internet Banking" },
     2: { label: "Credit/Debit Card" },
     3: { label: "Paypal" },
     4: { label: "PromptPay" },
     5: { label: "USDT" },
     6: { label: "Skrill" },
     7: { label: "WebMoney" },
     8: { label: "Perfect Money" },
     9: { label: "WeChat" },
     10: { label: "Alipay" },
     11: { label: "UUnionPay Your Way" },
};

export default withRouter(GridTableTran);
