import { Button, Grid, InputAdornment, Stack, Switch, TextField, Typography } from "@material-ui/core";

const BonusSettingCard = (props) => {
    const {
        state,
        language,
        handleChangeEnableBonus,
        handleChangeText,
    } = props;
    return (
        <Grid container columns={12} justifyContent="center" spacing={2}>
            <Grid item xs={12}>
                <Grid item container alignItems="center" sx={{ borderBottom: "solid 2px #4589C6" }}>
                    <Grid item xs={6} sx={{ fontSize: 24, fontWeight: 600, color: "#000" }}>
                        <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", my: 2 }}>
                            {language === "th" ? "ตั้งค่าโบนัส" : "Bonus Setting"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} display="flex" justifyContent="flex-end">
                        <Button
                            variant="contained"
                            className="btn-style"
                            sx={{ maxWidth: 260, backgroundColor: "#4589C6", my: 2 }}
                            disabled={!state.credit}
                        >
                            {language === "th" ? "บันทึก" : "Save"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000" }} my={3}>
                    {state.username} ({state.email})
                </Typography>
                <Grid item container alignItems="center">
                    <Grid xs={5} sm={2} sx={{ fontSize: { xs: 16, sm: 18 }, fontWeight: 600, color: "#000" }} >
                        {language === "th" ? "โบนัส" : "Bonus"}
                    </Grid>
                    <Grid item xs={7} sm={10}>
                        <Switch
                            checked={state.enable_bonus}
                            onChange={() => handleChangeEnableBonus(state.enable_bonus)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item container alignItems="center">
                    <Grid xs={5} sm={2} sx={{ fontSize: { xs: 16, sm: 18 }, fontWeight: 600, color: "#000" }} >
                        {language === "th" ? "ปรับวงเงินเครดิต" : "Adjust credit limit"}
                    </Grid>
                    <Grid item xs={7} sm={10}>
                        <TextField
                            InputProps={{
                                endAdornment: <InputAdornment position="end">$</InputAdornment>,
                            }}
                            size="small"
                            type="number"
                            placeholder="0.00"
                            name="credit"
                            onChange={(e) => handleChangeText(e)}
                            value={state.credit}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BonusSettingCard;
