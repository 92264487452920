import { Box, Button, Collapse, Grid, IconButton, List, ListItem, ListItemText, Stack, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import { makeStyles } from "@material-ui/styles";
import { tofieds } from "functions/Functions";
import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";

const theme = createMuiTheme();
const override = {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
};

const NestedListItem = ({ item, handleDataNest, header, level, language, page, showAll }) => {
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleShow = () => {
        setShow(!show);
    };

    // useEffect(() => {
    //     setShow(false);
    // }, [page]);

    useEffect(() => {
        setOpen(showAll);
        setShow(showAll);
    }, [showAll]);

    return (
        // <List sx={{ width: '100%', bgcolor: 'background.paper', py: 0 }} component="nav" aria-labelledby="nested-list-subheader">
        <List sx={{ width: '100%', bgcolor: colorStyle[Number(level % 13)].color, py: 0 }} component="nav" aria-labelledby="nested-list-subheader">
            <ListItem>
                <ListItemText
                    primary={
                        < React.Fragment >
                            <Grid container direction={"row"} alignItems="center" >
                                <Grid item xs={12} sm={12} md={2.5} >
                                    <Stack width="100%" direction="row" spacing={1} alignItems="center">
                                        <Typography
                                            noWrap
                                            sx={{ fontSize: 16, color: "#0d47a1", fontWeight: "bold" }}
                                        >
                                            {item.email}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={9} sm={9} md={3}>
                                    <Stack width="100%" direction="row" spacing={1} alignItems="center" sx={{ pl: level * 2 }}>
                                        <Typography
                                            noWrap
                                            sx={{ fontSize: 16, color: "#0d47a1", fontWeight: "bold" }}
                                        >
                                            ({Number(item.ib_status) === 1 ? "IB" : ""}{level + 1}) {item.name} {item.lastname}
                                        </Typography>
                                        {item.children && item.children.length > 0 && (
                                            <IconButton size="small" onClick={handleClick}>
                                                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        )}
                                    </Stack>
                                </Grid>
                                <Grid item xs={3} sm={3} md={1} display="flex" justifyContent={{ xs: "right", sm: "right", md: "left" }}>
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            handleDataNest(item.user_id);
                                            handleShow();
                                        }}>
                                        {
                                            show ? language === "th" ? "ซ่อนข้อมูล" : "Hide" :
                                                language === "th" ? "แสดงข้อมูล" : "Show"
                                        }
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={5.5} display="flex" justifyContent="left">
                                    <Grid item container direction={"row"} alignItems="center" >
                                        {header.map((field, index) => (
                                            <Grid key={index} item xs={4} sm={3} md={3} display="flex" justifyContent="center" my={0.5}>
                                                {show &&
                                                    <Stack width="100%" alignItems="center">
                                                        <Typography noWrap sx={{ fontSize: 12, color: "#0d47a1" }}>
                                                            {field.label}
                                                        </Typography>
                                                        {item[field.value] !== undefined ?
                                                            <Typography noWrap sx={{ fontSize: 16, color: "#0d47a1" }}>
                                                                {tofieds(item[field.value]) || "0.00"}
                                                            </Typography>
                                                            :
                                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                                <BeatLoader
                                                                    color="#0d47a1"
                                                                    loading={item[field.value] !== undefined ? false : true}
                                                                    cssOverride={override}
                                                                    size={5}
                                                                    aria-label="Loading Spinner"
                                                                    data-testid="loader"
                                                                />
                                                            </Box>
                                                        }
                                                    </Stack>
                                                }
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                />
            </ListItem>
            {
                item.children && item.children.length > 0 && (
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {item.children.map((childItem, index) => (
                                <NestedListItem key={index} item={childItem} handleDataNest={handleDataNest} header={header} level={level + 1} language={language} page={page} showAll={showAll} />
                            ))}
                        </List>
                    </Collapse>
                )
            }
        </List >
    );
};

const GridTableIBMemberNest = (props) => {
    const classes = useStyles();
    // const [showAll, setShowAll] = useState(false);

    // const handleShowAll = () => {
    //     setShowAll(!showAll);
    // };

    return (
        <Grid className="table-btn">
            <Grid item container direction={"row"} borderBottom={"2px solid #EBEBEB"} paddingBottom="10px">
                <Grid item xs={12}>
                    <Stack direction="row" width="100%" display="flex" alignItems="center" spacing={3}>
                        <Typography my={1} className={classes.fontStyleTitle}>Sub IB</Typography>
                        {props.showAll ?
                            <IconButton
                                onClick={() => {
                                    props.handleShowAll();
                                    props.getIBNest();
                                }}
                            >
                                <CancelPresentationIcon fontSize="large" />
                            </IconButton>
                            :
                            <IconButton
                                onClick={() => {
                                    props.handleShowAll();
                                    props.getAllIBNest();
                                }}
                            >
                                <SettingsOverscanIcon fontSize="large" />
                            </IconButton>
                        }
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    {props.body.map((item, index) => (
                        <NestedListItem key={index} item={item} handleDataNest={props.handleDataNest} header={props.head} level={0} language={props.language} page={props.page} showAll={props.showAll} />
                    ))}
                </Grid>
            </Grid>
            <Grid container justifyContent="center" mt={5}>
                {props.body.length === 0 && (
                    <Typography noWrap sx={{ fontSize: 16, color: "#0d47a1" }}>
                        {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                    </Typography>
                )}
                {/* {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />} */}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        minWidth: 400,
    },
    fontStyleTitle: {
        fontSize: 18,
        fontWeight: 600,
        color: "#000",
    },
}));

const colorStyle = {
    0: { color: "#FFFFFF" },
    1: { color: "#F8F8F8" },
    2: { color: "#F5F5F5" },
    3: { color: "#F0F0F0" },
    4: { color: "#E8E8E8" },
    5: { color: "#E0E0E0" },
    6: { color: "#DCDCDC" },
    7: { color: "#D8D8D8" },
    8: { color: "#D3D3D3" },
    9: { color: "#D0D0D0" },
    10: { color: "#C8C8C8" },
    11: { color: "#C0C0C0" },
    12: { color: "#BEBEBE" },
};

export default GridTableIBMemberNest;
