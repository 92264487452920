import { Container } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { BANADMIN, CHANGEPASSWORDADMIN, DELETEADMIN, EDITADMIN, GET, GETADMIN, POST } from "service";
import AdminCard from "./Component/AdminCard";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";
import ModalChangePassword from "./Component/ModalChangePassword";
class Admin extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data_admin: [],
               open: false,
               loading: false,
               page: 1,
               openModalChangePass: false,
          };
     }

     componentDidMount = () => {
          this.getAdmin();
     };

     getAdmin = async () => {
          try {
               this.setState({ loading: true });
               let getAdmin = await GET(GETADMIN);
               if (getAdmin.success) {
                    this.setState({ data_admin: getAdmin.result });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: getAdmin.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     onClickAdd = () => {
          this.props.history.push("/admin/add");
     };

     onClickDelete = (admin_id, username) => {
          const { language } = this.state;
          swal.fire({
               title: `${language === "th" ? "ลบผู้ใช้งาน" : "Delete user"}`,
               text: `${language === "th" ? `คุณต้องการลบบัญชี ${username}?` : `Do you want to delete the account ${username}?`}`,
               icon: "question",
               showCancelButton: true,
               confirmButtonText: "Submit",
               cancelButtonText: "Cancel",
          }).then(async (result) => {
               if (result.isConfirmed) {
                    try {
                         this.setState({ loading: true });
                         let del_admin = await POST(DELETEADMIN, { admin_id });
                         if (del_admin.success) {
                              this.getAdmin();
                              this.setState({ loading: false });
                              swal.fire({
                                   title: "สำเร็จ", text: "ลบผู้ดูแลสำเร็จ", icon: "success",
                                   showConfirmButton: true
                              });
                         } else {
                              this.setState({ loading: false });
                              swal.fire({
                                   title: "ไม่สำเร็จ", text: del_admin.message, icon: "warning",
                                   showConfirmButton: true
                              });
                         }
                    } catch (error) {
                         this.setState({ loading: false });
                         swal.fire({
                              title: "ไม่สำเร็จ", text: error, icon: "warning",
                              showConfirmButton: true
                         });
                    }
               }
          });
     };


     onClickBan = (admin_id, username) => {
          const { language } = this.state;
          swal.fire({
               title: `${language === "th" ? "แบนผู้ใช้งาน" : "Ban user"}`,
               text: `${language === "th" ? `คุณต้องการแบนบัญชี ${username}?` : `Do you want to ban the account ${username}?`}`,
               icon: "question",
               showCancelButton: true,
               confirmButtonText: "Submit",
               cancelButtonText: "Cancel",
          }).then(async (result) => {
               if (result.isConfirmed) {
                    try {
                         this.setState({ loading: true });
                         let ban_admin = await POST(BANADMIN, { status: 2, admin_id });
                         if (ban_admin.success) {
                              this.getAdmin();
                              this.setState({ loading: false });
                              swal.fire({
                                   title: "สำเร็จ", text: "แบนผู้ดูแลสำเร็จ", icon: "success",
                                   showConfirmButton: true
                              });
                         } else {
                              this.setState({ loading: false });
                              swal.fire({
                                   title: "ไม่สำเร็จ", text: ban_admin.message, icon: "warning",
                                   showConfirmButton: true
                              });
                         }
                    } catch (error) {
                         this.setState({ loading: false });
                         swal.fire({
                              title: "ไม่สำเร็จ", text: error, icon: "warning",
                              showConfirmButton: true
                         });
                    }
               }
          });
     };

     onClickUnbanAccount = (admin_id, username) => {
          const { language } = this.state;
          swal.fire({
               title: `${language === "th" ? "ยกเลิกการแบนผู้ใช้งาน" : "Cancel ban user"}`,
               text: `${language === "th" ? `คุณต้องการยกเลิกแบนบัญชี ${username}?` : `Do you want to cancel ban the account ${username}?`}`,
               icon: "question",
               showCancelButton: true,
               confirmButtonText: "Submit",
               cancelButtonText: "Cancel",
          }).then(async (result) => {
               if (result.isConfirmed) {
                    try {
                         this.setState({ loading: true });
                         let res = await POST(BANADMIN, { status: 1, admin_id });
                         if (res.success) {
                              this.getAdmin();
                              this.setState({ loading: false });
                              swal.fire({
                                   title: "สำเร็จ", text: "ยกเลิกแบนผู้ดูแลสำเร็จ", icon: "success",
                                   showConfirmButton: true
                              });
                         } else {
                              this.setState({ loading: false });
                              swal.fire({
                                   title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                                   showConfirmButton: true
                              });
                         }
                    } catch (error) {
                         this.setState({ loading: false });
                         swal.fire({
                              title: "ไม่สำเร็จ", text: error, icon: "warning",
                              showConfirmButton: true
                         });
                    }
               }
          });
     };


     onClickEdit = async () => {
          const { name, lastname, phone, line_id, admin_id } = this.state;
          try {
               this.setState({ loading: true });
               let edit_admin = await POST(EDITADMIN, { name, lastname, phone, line_id, admin_id });
               if (edit_admin.success) {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "สำเร็จ", text: "แก้ไขผู้ดูแลสำเร็จ", icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
                    window.location.reload();
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: edit_admin.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     onClickResetPassword = async () => {
          const { new_password, confirm_password, language, admin_id } = this.state;
          if (new_password && confirm_password) {
               if (new_password.length < 8) {
                    swal.fire({
                         title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                         text: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีอย่างน้อย 8 ตัวอักษรขึ้นไป" : "Your new password needs a minimum of eight characters"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
                    this.setState({
                         error: true,
                         error_massage: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีอย่างน้อย 8 ตัวอักษรขึ้นไป" : "Your new password needs a minimum of eight characters"}`,
                    });
               } else if (new_password.search(/[a-z]/) < 0) {
                    swal.fire({
                         title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                         text: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์เล็ก" : "Your new password needs a lower case letter"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
                    this.setState({
                         error: true,
                         error_massage: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์เล็ก" : "Your new password needs a lower case letter"}`,
                    });
               } else if (new_password.search(/[A-Z]/) < 0) {
                    swal.fire({
                         title: `${language === "th" ? `${language === "th" ? "ไม่สำเร็จ" : "Warning"}` : "Warning"}`,
                         text: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์ใหญ่" : "Your new password needs an uppser case letter"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
                    this.setState({
                         error: true,
                         error_massage: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์ใหญ่" : "Your new password needs an uppser case letter"}`,
                    });
               } else if (new_password.search(/[0-9]/) < 0) {
                    swal.fire({
                         title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                         text: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวเลข" : "Your new password needs a number"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
                    this.setState({ error: true, error_massage: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวเลข" : "Your new password needs a number"}` });
               } else {
                    if (new_password === confirm_password) {
                         try {
                              this.setState({ loading: true });
                              let change_password = await POST(CHANGEPASSWORDADMIN, { admin_id, password: new_password });
                              if (change_password.success) {
                                   this.setState({ loading: false });
                                   swal.fire({
                                        title: "สำเร็จ", icon: "success",
                                        // timer: 6000, 
                                        showConfirmButton: true
                                   });
                                   this.getAdmin();
                              } else {
                                   this.setState({ loading: false });
                                   swal.fire({
                                        title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                                        text: change_password.message,
                                        icon: "warning",
                                        // timer: 6000,
                                        showConfirmButton: true,
                                   });
                              }
                         } catch (error) {
                              this.setState({ loading: false });
                              swal.fire({
                                   title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`, text: error, icon: "warning",
                                   // timer: 6000, 
                                   showConfirmButton: true
                              });
                         }
                    } else {
                         this.setState({ loading: false });
                         swal.fire({
                              title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                              text: `${language === "th" ? "กรุณากรอกรหัสผ่านให้ตรงกัน" : "Password Not Match"}`,
                              icon: "warning",
                              // timer: 6000,
                              showConfirmButton: true,
                         });
                    }
               }
          }
          else {
               this.setState({ loading: false });
               swal.fire({
                    title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                    text: `${language === "th" ? "กรุณากรอกรหัสผ่าน" : "Please enter your password."}`,
                    icon: "warning",
                    // timer: 6000,
                    showConfirmButton: true,
               });
          }
     };


     onClickOpen = (id) => {
          const { data_admin } = this.state;
          let find_data = data_admin.find((el) => el.admin_id === id);
          this.setState({ open: true, admin_id: id, name: find_data.name, lastname: find_data.lastname, phone: find_data.phone, line_id: find_data.line_id });
     };

     onClickClose = () => {
          this.setState({ open: false });
     };

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };

     handleCloseModal = () => {
          this.setState({
               openModalChangePass: false,
          });
     };

     handleOpenModal = (id, username) => {
          // console.log('type', type)
          this.setState({ openModalChangePass: true, admin_id: id, profile_name: username });
     };

     render() {
          const language = localStorage.getItem("language");
          const { data_admin, open, loading, page, openModalChangePass } = this.state;
          return (
               <Container maxWidth="xl">
                    <Loading open={loading} />
                    <AdminCard
                         language={language}
                         data_admin={data_admin}
                         onClickAdd={this.onClickAdd}
                         onClickDelete={this.onClickDelete}
                         open={open}
                         onClickOpen={this.onClickOpen}
                         onClickClose={this.onClickClose}
                         onClickEdit={this.onClickEdit}
                         state={this.state}
                         handleChangeText={this.handleChangeText}
                         handleChangePage={this.handleChangePage}
                         page={page}
                         onClickBan={this.onClickBan}
                         onClickUnbanAccount={this.onClickUnbanAccount}
                         handleOpenModal={this.handleOpenModal}
                    />
                    <ModalChangePassword
                         language={language}
                         open={openModalChangePass}
                         handleClose={this.handleCloseModal}
                         state={this.state}
                         handleChange={this.handleChangeText}
                         onClickResetPassword={this.onClickResetPassword}
                    />
               </Container>
          );
     }
}

export default withRouter(Admin);
