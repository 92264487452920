import { Container } from "@material-ui/core";
import moment from "moment";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GET, GETADMIN, GETAGENTUSERINSALESALE, GETAGENTUSERINSALESALEID, GETINOUTSALEADMIN, GETINOUTSALEUSER, GETSALELOTALL, GETSALELOTBYSEARCH, GETUSERINSALEADMIN, GETUSERINSALESALE, POST } from "service";
import swal from "sweetalert2";
import SaleCard from "./components/SaleCard";

export class Sale extends Component {
     constructor(props) {
          super(props);

          this.state = {
               language: localStorage.getItem("language"),
               date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
               date_end: new Date(),
               sale: [{ label: "เลือก Sale", value: "null", disabled: true }],
               sale_select: "null",
               role: localStorage.getItem("role"),
               adminList: [],
               page: 1,
               search_text: '',
               data_head: [],
               data_head_search: [],
               data_lot_search: [],
               data_lot_all: [],
               showAll: false,
               search_check: false,
          };
     }
     componentDidMount = () => {
          this.getAdmin();
          if (this.state.role === "sale") {
               this.submitSearch();
               // this.getSaleLotBySearch();
               this.getSaleLotAll();
          }
     };

     getSaleLotBySearch = async () => {
          const { language, date_start, date_end, search_text } = this.state;
          let start = moment(date_start).format("YYYY-MM-DD");
          let end = moment(date_end).format("YYYY-MM-DD");
          try {
               let res = await POST(GETSALELOTBYSEARCH, { lang: language, date_start: start, date_end: end, text_search: search_text });
               if (res.success) {
                    // console.log(res.result);
                    this.setState({ data_lot_search: res.result })
               }
          } catch (error) {
               console.log("error", error);
          }
     };

     getSaleLotAll = async () => {
          const { language } = this.state;
          try {
               let res = await POST(GETSALELOTALL, { lang: language });
               if (res.success) {
                    // console.log(res.result);
                    this.setState({ data_lot_all: res.result })
               }
          } catch (error) {
               console.log("error", error);
          }
     };

     getAdmin = async () => {
          const { sale } = this.state;
          try {
               let getsale = await GET(GETADMIN);
               if (getsale.success) {
                    let result = getsale.result;
                    result.map((el, i) => {
                         if (el.role === "sale") {
                              sale.push({ label: `${el.name} ${el.lastname}`, value: el.admin_id });
                         }
                    });
                    this.setState({ sale });
               }
          } catch (error) {
               console.log("error", error);
          }
     };

     getUserInSale = async () => {
          const { role, date_start, date_end, sale_select, language, search_text } = this.state;
          let start = moment(date_start).format("YYYY-MM-DD");
          let end = moment(date_end).format("YYYY-MM-DD");
          if (role === "admin") {
               if (date_start && date_end) {
                    try {
                         let getuserAdmin = await POST(GETUSERINSALEADMIN, { admin_id: sale_select, date_start: start, date_end: end });
                         if (getuserAdmin.success) {
                              let result = getuserAdmin.result;
                              this.setState({ adminList: result.data, sumDeposit: result.sumDeposit, sumWithDraw: result.sumWithDraw, allLot: result.allLot });
                         }
                    } catch (error) {
                         console.log("error", error);
                    }
               } else {
                    swal.fire({
                         title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                         text: `${language === "th" ? "กรุณาเลือกวันที่ให้ครบ" : "Please select date complete"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
               }
          } else {
               if (date_start && date_end) {
                    try {
                         let getuserSale = await POST(GETUSERINSALESALE, { admin_id: sale_select, date_start: start, date_end: end, text_search: search_text });
                         if (getuserSale.success) {
                              let result = getuserSale.result;
                              // console.log(result.net_deposit);
                              this.setState({ adminList: result.data, data_head_search: { sumDeposit: result.sumDeposit, sumWithDraw: result.sumWithDraw } });
                         }
                    } catch (error) {
                         console.log("error", error);
                    }
               } else {
                    swal.fire({
                         title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                         text: `${language === "th" ? "กรุณาเลือกวันที่ให้ครบ" : "Please select date complete"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
               }
          }
     };

     getUserSaleAllAgent = async () => {
          const { date_start, date_end, search_text } = this.state;
          let start = moment(date_start).format("YYYY-MM-DD");
          let end = moment(date_end).format("YYYY-MM-DD");
          try {
               let getuserSale = await POST(GETAGENTUSERINSALESALE, { date_start: start, date_end: end, text_search: search_text });
               if (getuserSale.success) {
                    let result = getuserSale.result;
                    this.setState({ adminList: result.data, data_head_search: { sumDeposit: result.sumDeposit, sumWithDraw: result.sumWithDraw } });
               }
          } catch (error) {
               console.log("error", error);
          }
     };

     handleSearch = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     submitSearch = async () => {
          const { sale_select, role, language } = this.state;
          if (role === "admin") {
               if (sale_select !== "null") {
                    try {
                         let getInOutAdmin = await POST(GETINOUTSALEADMIN, { admin_id: sale_select });
                         if (getInOutAdmin.success) {
                              let result = getInOutAdmin.result;
                              this.setState({ deposit: result.deposit, withdraw: result.withdraw });
                              this.getUserInSale();
                         }
                    } catch (error) {
                         console.log("error", error);
                    }
               } else {
                    swal.fire({
                         title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                         text: `${language === "th" ? "กรุณาเลือก Sale" : "Please select sale"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
               }
          } else {
               try {
                    let getInOutUser = await GET(GETINOUTSALEUSER);
                    if (getInOutUser.success) {
                         let result = getInOutUser.result;
                         this.setState({ showAll: false, search_check: true })
                         this.setState({ data_head: result, data_head_search: [] });
                         this.getUserInSale();
                         this.getSaleLotBySearch();
                    }
               } catch (error) {
                    console.log("error", error);
               }
          }
     };

     getSaleAgentBySale = async (user_id) => {
          const { date_start, date_end } = this.state;
          let start = moment(date_start).format("YYYY-MM-DD");
          let end = moment(date_end).format("YYYY-MM-DD");
          this.setState({ loading: true, search_check: false });
          // this.setState({ data_header: [] })

          try {
               let res = await POST(GETAGENTUSERINSALESALEID, { date_start: start, date_end: end, user_id });
               if (res.success) {
                    // console.log(res.result);
                    this.updateData(user_id, res.result.agent_fee)
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    showConfirmButton: true
               });
          }

     };

     handleDateStartChange = async (date) => {
          // await this.setState({ date_start: date });
          // this.getUserInSale();
          this.setState({ date_start: date });
     };

     handleDateEndChange = async (date) => {
          // await this.setState({ date_end: date });
          // this.getUserInSale();
          this.setState({ date_end: date });
     };

     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };

     handleShowAll = () => {
          const { showAll } = this.state;
          this.setState({ showAll: !showAll })
     };

     updateData = (user_id, agent_fee) => {
          const { adminList } = this.state;

          // console.log('adminList', adminList);

          const updatedData = adminList.map(item => (item.user_id === user_id ? { ...item, agent_fee } : item));

          // console.log('updatedData', updatedData);

          this.setState({ adminList: updatedData });
     };


     render() {
          const { language, sale, deposit, withdraw, adminList, sumDeposit, sumWithDraw, role, allLot, net_deposit, page, data_head, data_head_search, data_lot_search, data_lot_all, showAll, search_check } = this.state;
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    <SaleCard
                         role={role}
                         handleSearch={this.handleSearch}
                         submitSearch={this.submitSearch}
                         state={this.state}
                         language={language}
                         handleDateStartChange={this.handleDateStartChange}
                         handleDateEndChange={this.handleDateEndChange}
                         sale={sale}
                         deposit={deposit}
                         withdraw={withdraw}
                         adminList={adminList}
                         sumDeposit={sumDeposit}
                         sumWithDraw={sumWithDraw}
                         allLot={allLot}
                         net_deposit={net_deposit}
                         page={page}
                         handleChangePage={this.handleChangePage}
                         data_head={data_head}
                         data_head_search={data_head_search}
                         data_lot_search={data_lot_search}
                         data_lot_all={data_lot_all}
                         handleShowAll={this.handleShowAll}
                         showAll={showAll}
                         getSaleAgentBySale={this.getSaleAgentBySale}
                         getUserSaleAllAgent={this.getUserSaleAllAgent}
                         getUserInSale={this.getUserInSale}
                         search_check={search_check}
                    />
               </Container>
          );
     }
}

export default withRouter(Sale);
