import { Backdrop, Fade, Modal, Grid, Typography, FormControl, FormControlLabel, Radio, RadioGroup, Button, Stack } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { GridTextField } from "components";
import { textFiled } from "functions/Static";
import CloseIcon from "@material-ui/icons/Close";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const ModalChangePassword = (props) => {
    const { open, handleClose, language, handleChange, onClickResetPassword, state } = props;
    const classes = useStyles();
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Grid className={classes.boxStyle}>
                    <Grid container justifyContent="flex-end" xs={12}>
                        <Button onClick={() => handleClose()} className={classes.buttonClose}>
                            <CloseIcon fontSize="small" style={{ color: "#3b3b3b" }} />
                        </Button>
                    </Grid>
                    <Grid container spacing={1} justifyContent={"center"}>
                        <Grid item xs={12} my={3}>
                            <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6", textAlign: "center" }}>
                                Change password
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} container spacing={2}>
                            <Grid item xs={4}>
                                <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000" }}>
                                    Username
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000" }}>
                                    {state.profile_name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={8} container spacing={2}>
                            <GridTextField data={textFiled.reset_password} state={state} handleChange={handleChange} />
                            {/* <Box mx={2} my={3}>
                         <Typography className={classes.msgTitle}>
                              {language === "th" ?
                                   "เงื่อนไขการตั้งรหัสผ่าน"
                                   :
                                   "Password requirements"
                              }
                         </Typography>
                         <Typography className={classes.msgDetail}>
                              {language === "th" ?
                                   "• โปรดตั้งรหัสผ่านความยาว 8 ตัวอักษรขึ้นไป ประกอบไปด้วยตัวอักษรพิมพ์ใหญ่ พิมพ์เล็ก ตัวเลข และสัญลักษณ์พิเศษ เช่น !,@,#,$,*"
                                   :
                                   "• Please set a password that is at least 8 characters long, containing uppercase letters, lowercase letters, numbers, and special characters such as !, @, #, $, *."
                              }
                         </Typography>
                    </Box> */}
                        </Grid>
                        <Grid item container justifyContent={"center"} mb={3}>
                            <Button className={classes.btnStyle} variant="outlined" color="error" onClick={() => handleClose()}>
                                Close
                            </Button>
                            <Button
                                className={classes.btnStyle}
                                variant="contained"
                                style={{ backgroundColor: "#4589C6" }}
                                onClick={() => {
                                    onClickResetPassword();
                                    handleClose();
                                }}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Fade>
        </Modal >
    );
};
const useStyles = makeStyles((theme) => ({
    boxStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        maxWidth: "820px",
        minWidth: "280px",
        bgcolor: "#707070",
        background: "#fff",
        boxShadow: "rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px",
        padding: "10px 18px 25px",
        borderRadius: "8px",
    },
    btnStyle: { maxWidth: 250, fontSize: 16, width: "100%", borderRadius: 40, marginTop: 10, marginLeft: 5, marginRight: 5, marginTop: 50 },
    buttonClose: {
        backgroundColor: "Transparent",
        backgroundRepeat: "no-repeat",
        border: "none",
        cursor: "pointer",
        overflow: "hidden",
        "&:hover": {
            backgroundColor: "Transparent",
        },
    },
    msgTitle: {
        fontSize: 14,
        color: "#676767",
        textAlign: "justify",
        marginTop: 5,
    },
    msgDetail: {
        fontSize: 14,
        color: "#676767",
        textAlign: "justify",
        marginTop: 5,
        textIndent: "15px"
    },
}));
export default ModalChangePassword;
