import { Container } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import ManageUserCard from "./ManageUser/ManageUserCard";
import { POST, PROFILEBYUSER, UPDATEPROFILEENABLE } from "service";
import swal from "sweetalert2";
import BonusSettingCard from "./ManageUser/BonusSettingCard";

class BonusSetting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: localStorage.getItem("language"),
            loading: false,
            page: 1,
            role: localStorage.getItem("role"),
            user_id: this.props.match.params.id,
            enable_bonus: null,
            credit: null,
        };
    }

    componentDidMount = () => {
        this.getProfile();
    };

    getProfile = async () => {
        let { user_id } = this.state;
        try {
            this.setState({ loading: true });
            let profile = await POST(PROFILEBYUSER, { user_id });
            if (profile.success) {
                let result = profile.result;
                this.setState({
                    profile: result,
                    username: `${result.name} ${result.lastname}`,
                    phone: result.phone,
                    email: result.email,
                    name: result.name,
                    lastname: result.lastname,
                    loading: false,
                    enable_bonus: result.enable_bonus,
                });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: profile.message, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
        }
    };

    handleChangeEnableBonus = (enable_bonus) => {
        const { language, user_id } = this.state;
        swal.fire({
            text: `${language === "th" ? "เปลี่ยนสถานะโบนัส" : "Change enable bonus"}`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let res = await POST(UPDATEPROFILEENABLE, { user_id, data: { enable_bonus: !enable_bonus } });
                    if (res.success) {
                        this.getProfile();
                        swal.fire({
                            title: "สำเร็จ", text: "Change to status success", icon: "success",
                            showConfirmButton: true
                        });
                    } else {
                        swal.fire({
                            title: "Warning", text: res.message, icon: "warning",
                            showConfirmButton: true
                        });
                    }
                } catch (error) {
                    swal.fire({
                        title: "Warning", text: error, icon: "warning",
                        showConfirmButton: true
                    });
                }
            }
        });
    };

    handleChangeText = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };

    render() {
        const { language, loading, page, role } = this.state;
        return (
            <Container maxWidth="xl">
                <Loading open={loading} />
                <BonusSettingCard
                    state={this.state}
                    language={language}
                    role={role}
                    handleChangeText={this.handleChangeText}
                    handleChangePage={this.handleChangePage}
                    page={page}
                    handleChangeEnableBonus={this.handleChangeEnableBonus}
                />
            </Container>
        );
    }
}

export default withRouter(BonusSetting);
