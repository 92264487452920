import { Container, Grid } from "@material-ui/core";
import React, { Component } from "react";
import HeaderTypography from "../../components/HeaderTypography/HeaderTypography";
import { withRouter } from "react-router-dom";
import TableWithdrawal from "./Components/TableWithdrawal";
import SearchTable from "./Components/SearchTable";
import ModalEditExchange from "./Components/ModalEditExchange";
import { GET, POST, WITHDRAWEDIT, WITHDRAWCONFIG } from "service";
import swal from "sweetalert2";
import Sidebar from "./Components/Sidebar";
import Loading from "components/Loading/Loading";

const dataTable = [
     {
          id: 1,
          fee: 0,
          min: 100,
          max: 50000,
          limit: 50000,
     },
     {
          id: 2,
          fee: 0,
          min: 100,
          max: 50000,
          limit: 50000,
     },
     {
          id: 3,
          fee: 0,
          min: 100,
          max: 50000,
          limit: 50000,
     },
     {
          id: 4,
          fee: 0,
          min: 100,
          max: 50000,
          limit: 50000,
     },
     {
          id: 5,
          fee: 0,
          min: 100,
          max: 50000,
          limit: 50000,
     },
     {
          id: 6,
          fee: 0,
          min: 100,
          max: 50000,
          limit: 50000,
     },
];

class SettingDeposit extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data: [],
               page: 1,
               search_text: "",
               open: false,
               loading: false,
          };
     }
     componentDidMount = () => {
          this.getWithdrawConfig();
     };
     getWithdrawConfig = async () => {
          try {
               this.setState({ loading: true });
               let withdraw = await GET(WITHDRAWCONFIG);
               if (withdraw.success) {
                    // let withdraw_array = [];
                    // withdraw_array.push(withdraw.result);
                    this.setState({ withdraw: withdraw.result, data: withdraw.result, loading: false });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               this.setState({ loading: false });
               console.log(`error`, error);
          }
     };
     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };
     handleSearch = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };
     submitSearch = () => {
          let { search_text, withdraw } = this.state;
          let data = withdraw.filter(
               (el) =>
                    (search_text ? el.min.toString().toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.max.toString().toLowerCase().includes(search_text.toLowerCase()) : el)
          );
          this.setState({ data, page: 1 });
     };
     onClickOpenModal = (id, min, max) => {
          this.setState({ open: true, min, max, withdraw_id: id });
     };
     onClickCloseModal = () => {
          this.setState({ open: false });
     };
     handleChangeText = (e) => {
          this.setState({ [e.target.name]: Number(e.target.value) });
     };
     onClickEdit = async () => {
          const { min, max, withdraw_id } = this.state;
          try {
               if (min && max && withdraw_id) {
                    this.setState({ loading: true });
                    let edit = await POST(WITHDRAWEDIT, { min, max, withdraw_id });
                    if (edit.success) {
                         this.setState({ loading: false });
                         swal.fire({ title: "สำเร็จ", text: "แก้ไขสำเร็จ", icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                         this.getWithdrawConfig();
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "สำเร็จ", text: edit.message, icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "ไม่สำเร็จ", text: "กรุณากรอกให้ครบ", icon: "error", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "ไม่สำเร็จ", text: error, icon: "error", 
               // timer: 6000, 
               showConfirmButton: true });
          }
          this.setState({ open: false });
     };
     render() {
          const language = localStorage.getItem("language");
          const { page, data, open, loading } = this.state;
          return (
               <Container maxWidth="xl">
                    <Loading open={loading} />
                    <Grid container spacing={2}>
                         <Grid item xs={3}>
                              <Sidebar title="Setup Withdrawal" pageName="SetupWithdrawal" />
                         </Grid>
                         <Grid item xs={9}>
                              <HeaderTypography title={"Setup Withdrawal"} />
                              <SearchTable
                                   language={language}
                                   data={data}
                                   page={page}
                                   handleChangePage={this.handleChangePage}
                                   handleSearch={this.handleSearch}
                                   state={this.state}
                                   submitSearch={this.submitSearch}
                              />
                              <TableWithdrawal data={data} onClickOpenModal={this.onClickOpenModal} />
                              <ModalEditExchange
                                   open={open}
                                   state={this.state}
                                   onClickCloseModal={this.onClickCloseModal}
                                   handleChangeText={this.handleChangeText}
                                   onClickEdit={this.onClickEdit}
                                   title={"Withdrawal"}
                              />
                         </Grid>
                    </Grid>
               </Container>
          );
     }
}

export default withRouter(SettingDeposit);
