import { Button, Collapse, Grid, IconButton, List, ListItem, ListItemText, Pagination, Stack, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const theme = createMuiTheme();
const override = {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
};

const NestedListItem = ({ item, header, level, language, showAll, role, onClickChangeToManager, onClickOpen, onClickCancelUserSale }) => {
    const [open, setOpen] = useState(false);
    const history = useHistory();

    const onClickNetwork = (id) => {
        history.push(`/network_user/${id}`);
    };

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        setOpen(showAll);
    }, [showAll]);


    return (
        <List sx={{ width: '100%', bgcolor: colorStyle[Number(level % 13)].color, py: 0 }} component="nav" aria-labelledby="nested-list-subheader">
            <ListItem>
                <ListItemText
                    primary={
                        < React.Fragment >
                            <Grid columns={14} container direction={"row"} alignItems="center" rowSpacing={{ xs: 1, sm: 1, md: "none" }}>
                                <Grid item xs={14} sm={7} md={role === "admin" ? 3 : 4} >
                                    <Stack width="100%" direction="row" spacing={1} alignItems="center" sx={{ pl: level * 2 }}>
                                        <Typography
                                            noWrap
                                            sx={{ fontSize: 14, color: "#0d47a1", fontWeight: "bold" }}
                                        >
                                            {level === 0 ? "●" : `(${level})`} {item.name} {item.lastname}
                                        </Typography>
                                        {item.children && item.children.length > 0 && (
                                            <IconButton size="small" onClick={handleClick}>
                                                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        )}
                                    </Stack>
                                </Grid>
                                <Grid item xs={14} sm={7} md={2.5} >
                                    <Typography
                                        noWrap
                                        sx={{ fontSize: 14, color: "#0d47a1", fontWeight: "bold" }}
                                    >
                                        {item.email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4.5} sm={3} md={1} display="flex" justifyContent={item.phone ? "flex-start" : "center"}>
                                    <Typography
                                        noWrap
                                        sx={{ fontSize: 14, color: "#0d47a1" }}
                                    >
                                        {item.phone ? item.phone : "-"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4.5} sm={3} md={1} display="flex" justifyContent="flex-start">
                                    <Typography
                                        noWrap
                                        sx={{ fontSize: 14, color: "#0d47a1" }}
                                    >
                                        {moment(item.created_at).format("DD/MM/YYYY")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} sm={3} md={1} display="flex" justifyContent="flex-start">
                                    <Typography
                                        noWrap
                                        mx={{ xs: "none", sm: 1 }}
                                        sx={{ fontSize: 14, color: "#0d47a1" }}
                                    >
                                        {item.ib_status === 2 && "ยังไม่สมัคร IB"}
                                        {(item.ib_status === 1 && item.is_ib === 1) && "เป็น IB"}
                                        {(item.ib_status === 1 && item.is_ib !== 1) && "เป็น SUB IB"}
                                        {item.ib_status === 4 && "รอการยืนยัน"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={5} md={2} >
                                    <Typography
                                        noWrap
                                        sx={{ fontSize: 14, color: "#0d47a1" }}
                                    >
                                        {item.admin_name} {item.admin_lastname}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4.5} sm={role === "admin" ? 4.5 : 2.5} md={role === "admin" ? 1 : 2.5} display="flex" justifyContent="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ fontSize: 12, maxWidth: 110, width: "100%", mx: 0.5 }}
                                        onClick={() => onClickNetwork(item.user_id)}
                                    >
                                        {language === "th" ? "Network" : "Network"}
                                    </Button>
                                </Grid>
                                {role === "admin" && (
                                    <Grid item xs={5} sm={4.5} md={1} display="flex" justifyContent="center">
                                        {item.ib_status !== 1 &&
                                            <Button
                                                variant="contained"
                                                style={{ backgroundColor: "#4589C6" }}
                                                sx={{ fontSize: 12, maxWidth: 110, width: "100%", mx: 0.5 }}
                                                onClick={() => onClickChangeToManager(item.user_id)}
                                            >
                                                {item.is_ib === 1 ? language === "th" ? "เปลี่ยนเป็น IB" : "TO IB" : language === "th" ? "เปลี่ยนเป็น SUB IB" : "TO SUB IB"}
                                            </Button>
                                        }
                                    </Grid>
                                )}
                                {/* {role === "normal" && (
                                    <Grid item xs={4.5} sm={role === "admin" ? 4.5 : 2.5} md={role === "admin" ? 1 : 3} display="flex" justifyContent="center">
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: "#4589C6" }}
                                            sx={{ fontSize: 12, maxWidth: 110, width: "100%", mx: 0.5 }}
                                            onClick={() => onClickChangeToManager(item.user_id)}
                                        >
                                            {language === "th" ? "เปลี่ยนเป็น Manager" : "Change to manager"}
                                        </Button>
                                    </Grid>
                                )} */}
                                {role === "admin" && (
                                    <Grid item xs={4.5} sm={4.5} md={1.5} display="flex" justifyContent="center">
                                        {level === 0 &&
                                            <Stack spacing={1} mx={0.5} width="100%" direction={{ xs: "column", sm: "row", md: "column" }} alignItems="center">
                                                <Button
                                                    variant="contained"
                                                    style={{ backgroundColor: "#4589C6" }}
                                                    sx={{ fontSize: 12, maxWidth: 110, width: "100%", }}
                                                    onClick={() => onClickOpen(item.user_id)}
                                                >
                                                    {language === "th" ? "เลือก Sale" : "Select Sale"}
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    style={{ backgroundColor: "#4589C6" }}
                                                    sx={{ fontSize: 12, maxWidth: 110, width: "100%" }}
                                                    onClick={() => onClickCancelUserSale(item.user_id)}
                                                >
                                                    {language === "th" ? "ยกเลิก Sale" : "Cancel Sale"}
                                                </Button>
                                            </Stack>
                                        }
                                    </Grid>
                                )}
                            </Grid>
                        </React.Fragment>
                    }
                />
            </ListItem>
            {
                item.children && item.children.length > 0 && (
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {item.children.map((childItem, index) => (
                                <NestedListItem
                                    key={index}
                                    item={childItem}
                                    header={header}
                                    level={level + 1}
                                    language={language}
                                    showAll={showAll}
                                    role={role}
                                    onClickChangeToManager={onClickChangeToManager}
                                    onClickOpen={onClickOpen}
                                    onClickCancelUserSale={onClickCancelUserSale}
                                />
                            ))}
                        </List>
                    </Collapse>
                )
            }
        </List >
    );
};

const TableManageUserNest = (props) => {
    const classes = useStyles();

    const [showAll, setShowAll] = useState(false);

    const handleShowAll = () => {
        setShowAll(!showAll)
    }

    return (
        <Grid container columns={14} className="table-btn">
            <Grid item xs={14}>
                <Stack direction="row" width="100%" display="flex" alignItems="center" spacing={3}>
                    <Typography my={1} className={classes.fontStyleTitle}>Network</Typography>
                    <IconButton
                        onClick={() => {
                            handleShowAll();
                        }}
                    >
                        {showAll ? <CancelPresentationIcon fontSize="large" /> : <SettingsOverscanIcon fontSize="large" />}
                    </IconButton>
                </Stack>
            </Grid>
            <Grid columns={14} container display={{ xs: "none", sm: "none", md: "flex" }} alignItems="center" backgroundColor="#4589c6" py={3} px={3}>
                <Grid item xs={14} sm={7} md={props.role === "admin" ? 3 : 4} >
                    <Typography
                        noWrap
                        sx={{ fontSize: 14, color: "#fff", fontWeight: "bold" }}
                    >
                        Username
                    </Typography>
                </Grid>
                <Grid item xs={14} sm={7} md={2.5} >
                    <Typography
                        noWrap
                        sx={{ fontSize: 14, color: "#fff", fontWeight: "bold" }}
                    >
                        E-mail
                    </Typography>
                </Grid>
                <Grid item xs={4.5} sm={3} md={1} display="flex" justifyContent="flex-start">
                    <Typography
                        noWrap
                        sx={{ fontSize: 14, color: "#fff", fontWeight: "bold" }}
                    >
                        Mobile
                    </Typography>
                </Grid>
                <Grid item xs={4.5} sm={3} md={1} display="flex" justifyContent="flex-start">
                    <Typography
                        noWrap
                        sx={{ fontSize: 14, color: "#fff", fontWeight: "bold" }}
                    >
                        Register Date
                    </Typography>
                </Grid>
                <Grid item xs={5} sm={3} md={1} display="flex" justifyContent="flex-start">
                    <Typography
                        noWrap
                        mx={{ xs: "none", sm: 1 }}
                        sx={{ fontSize: 14, color: "#fff", fontWeight: "bold" }}
                    >
                        IB Status
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={5} md={2} display="flex" justifyContent="flex-start">
                    <Typography
                        noWrap
                        sx={{ fontSize: 14, color: "#fff", fontWeight: "bold" }}
                    >
                        Sale Name
                    </Typography>
                </Grid>
                <Grid item xs={5} sm={4.5} md={props.role === "admin" ? 1 : 2.5} display="flex" justifyContent="center">
                    <Typography
                        noWrap
                        sx={{ fontSize: 14, color: "#fff", fontWeight: "bold" }}
                    >
                        Network
                    </Typography>
                </Grid>
                {props.role === "admin" && (
                    <>
                        <Grid item xs={5} sm={4.5} md={1} display="flex" justifyContent="center">
                            <Typography
                                noWrap
                                sx={{ fontSize: 14, color: "#fff", fontWeight: "bold" }}
                            >
                                Change to IB
                            </Typography>
                        </Grid>
                        <Grid item xs={5} sm={4.5} md={1.5} display="flex" justifyContent="center">
                            <Typography
                                noWrap
                                sx={{ fontSize: 14, color: "#fff", fontWeight: "bold" }}
                            >
                                Sale
                            </Typography>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid item xs={14} direction={"row"} borderBottom={"2px solid #EBEBEB"} paddingBottom="10px">
                {props.body.map((item, index) => (
                    <NestedListItem
                        key={index}
                        item={item}
                        header={props.header}
                        level={0}
                        language={props.language}
                        role={props.role}
                        showAll={showAll}
                        onClickChangeToManager={props.onClickChangeToManager}
                        onClickOpen={props.onClickOpen}
                        onClickCancelUserSale={props.onClickCancelUserSale}
                    />
                ))}
            </Grid>
            <Grid item xs={14} display="flex" justifyContent="center" mt={5}>
                {props.body.length === 0 && (
                    <Typography noWrap sx={{ fontSize: 16, color: "#0d47a1" }}>
                        {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                    </Typography>
                )}
                {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    table: {
        minWidth: 400,
    },
    fontStyleTitle: {
        fontSize: 18,
        fontWeight: 600,
        color: "#000",
    },
}));

const colorStyle = {
    0: { color: "#FFFFFF" },
    1: { color: "#F8F8F8" },
    2: { color: "#F5F5F5" },
    3: { color: "#F0F0F0" },
    4: { color: "#E8E8E8" },
    5: { color: "#E0E0E0" },
    6: { color: "#DCDCDC" },
    7: { color: "#D8D8D8" },
    8: { color: "#D3D3D3" },
    9: { color: "#D0D0D0" },
    10: { color: "#C8C8C8" },
    11: { color: "#C0C0C0" },
    12: { color: "#BEBEBE" },
};

export default TableManageUserNest;
