import { Avatar, Button, Card, CardContent, Grid, InputAdornment, Stack, TextField, Typography } from "@material-ui/core";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { makeStyles } from "@material-ui/styles";
import { GridTextField } from "components";
import { useState } from "react";
import DatePicker from "react-date-picker";
import ModalChangeUser from "./ModalChangeUser";
import moment from "moment";

const ProfileDetailCard = (props) => {
     const {
          data,
          state,
          language,
          submitEdit,
          disabled_textfield,
          submitSave,
          onChangeText,
          role,
          image_profile,
          onClickRegisterSubIb,
          onClickCancleSubIb,
          menuItem,
          onClickChangeIB,
          onClickWelcombonus,
          email_invite,
          handleDateChange,
          submitEditDob,
          updateDob,
     } = props;
     const [open, setOpen] = useState(false);
     const classes = useStyles();

     const onClickOpenChangeIB = () => {
          setOpen(true);
     };

     const onClickClose = () => {
          setOpen(false);
     };
     return (
          <Grid item container spacing={2} sx={{ justifyContent: "flex-start" }}>
               <ModalChangeUser open={open} onClickClose={onClickClose} state={state} onChangeText={onChangeText} menuItem={menuItem} onClickChangeIB={onClickChangeIB} />
               <Grid container item md={4} xs={12} justifyContent="center">
                    <Avatar sx={{ width: "300px", height: "300px" }} alt="Username" src={image_profile || props.img} />
               </Grid>
               <Grid item md={8} xs={12}>
                    <Card>
                         <CardContent>
                              <Grid container xs={12} direction="row" justifyContent="space-between">
                                   <Grid item>
                                        <Typography sx={{ my: 1, fontSize: 18, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>PERSONAL INFORMATION</Typography>
                                   </Grid>
                                   <Grid item>
                                        <Grid container direction={"row"}>
                                             {/* {data.welcome && (
                                                  <Button variant="contained" style={{ backgroundColor:"#4589C6"}} sx={{ mr: 2 }} onClick={onClickWelcombonus}>
                                                       Welcome Bonus
                                                  </Button>
                                             )} */}
                                             {/* <Button variant="contained" style={{ backgroundColor:"#4589C6"}} sx={{ mr: 2 }} onClick={onClickOpenChangeIB}>
                                                  ย้ายสายงาน
                                             </Button> */}
                                             {email_invite !== null ?
                                                  (
                                                       <Grid className={classes.fontInvite} sx={{ my: 1, mr: 2, backgroundColor: "#36A0F6" }}>
                                                            <Typography noWrap>Invite ID : {email_invite}</Typography>
                                                       </Grid>
                                                  )
                                                  :
                                                  (
                                                       <Grid className={classes.fontInvite} sx={{ my: 1, mr: 2, backgroundColor: "#36A0F6" }} >
                                                            <Typography noWrap>Invite ID : -</Typography>
                                                       </Grid>
                                                  )
                                             }

                                             {role !== 'sale' &&
                                                  <>
                                                       {data.ib_status !== 1 &&
                                                            (
                                                                 <Button variant="contained" color={"error"} sx={{ my: 1, mr: 2 }} onClick={onClickRegisterSubIb}>
                                                                      สมัคร Sub IB
                                                                 </Button>
                                                            )
                                                       }
                                                       {
                                                            data.invite_id !== null && data.ib_status === 1 && (
                                                                 <Button variant="contained" color={"error"} sx={{ my: 1, mr: 2 }} onClick={onClickCancleSubIb}>
                                                                      ยกเลิก Sub IB
                                                                 </Button>
                                                            )
                                                       }
                                                  </>
                                             }

                                             {/* {data.ib_status !== 1 && (
                                                  <Button variant="contained" color={"error"} sx={{ my: 1, mr: 2 }} onClick={onClickRegisterSubIb}>
                                                       สมัคร Sub IB
                                                  </Button>
                                             )}
                                             {data.invite_id !== null && data.ib_status === 1 && (
                                                  <Button variant="contained" color={"error"} sx={{ my: 1, mr: 2 }} onClick={onClickCancleSubIb}>
                                                       ยกเลิก Sub IB
                                                  </Button>
                                             )} */}
                                             {data.verify_doc === 1 ? (
                                                  <Grid className={classes.fontActive} sx={{ my: 1, backgroundColor: "#82C729" }}>
                                                       <Typography noWrap>Active</Typography>
                                                  </Grid>
                                             ) : data.verify_doc === 2 ? (
                                                  <Grid className={classes.fontActive} sx={{ my: 1, backgroundColor: "#FF0000" }}>
                                                       <Typography noWrap>Unverify</Typography>
                                                  </Grid>
                                             ) : data.verify_doc === 3 ? (
                                                  <Grid className={classes.fontActive} sx={{ my: 1, backgroundColor: "red" }}>
                                                       <Typography noWrap>Rejected</Typography>
                                                  </Grid>
                                             ) : data.verify_doc === 4 ? (
                                                  <Grid className={classes.fontActive} sx={{ my: 1, backgroundColor: "#002BB7" }}>
                                                       <Typography noWrap>Pending</Typography>
                                                  </Grid>
                                             ) : (
                                                  <Grid className={classes.fontActive} sx={{ my: 1, backgroundColor: "#FF0000" }}>
                                                       <Typography noWrap>Error</Typography>
                                                  </Grid>
                                             )}
                                        </Grid>
                                   </Grid>
                              </Grid>
                              <Grid container xs={12} direction="row" rowSpacing={2} mt={2}>
                                   {/* <Grid item xs={2}>
                                        <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000" }}>Full Name</Typography>
                                   </Grid>
                                   <Grid item xs={10} sx={{ fontSize: 18, color: "#000" }}>
                                        {data.name} {data.lastname || "-"}
                                   </Grid>
                                   <Grid item xs={2}>
                                        <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000" }}>Mobile</Typography>
                                   </Grid>
                                   <Grid item xs={10} sx={{ fontSize: 18, color: "#000" }}>
                                        {data.phone || "-"}
                                   </Grid>
                                   <Grid item xs={2}>
                                        <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000" }}>Email</Typography>
                                   </Grid>
                                   <Grid item xs={10} sx={{ fontSize: 18, color: "#000" }}>
                                        {data.email || "-"}
                                   </Grid>
                                   <Grid item xs={2}>
                                        <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000" }}>Location</Typography>
                                   </Grid>
                                   <Grid item xs={10} sx={{ fontSize: 18, color: "#000" }}>
                                        {data.address || "-"}
                                   </Grid> */}
                                   <GridTextField language={language} data={state.textfield_profile} state={state} handleChange={onChangeText} />
                                   <Grid item sm={4} textAlign="left" sx={{ display: { xs: "none", sm: "block" } }}>
                                        <Typography mt={2} fontWeight="bold" fontSize="18px" color="#060606">
                                             {language === "th" ? "วัน/เดือน/ปี เกิด" : "วัน/เดือน/ปี เกิด"}
                                        </Typography>
                                   </Grid>
                                   <Grid item xs={12} sm={8}>
                                        {/* <TextField
                                             fullWidth
                                             type="date"
                                             onChange={(e) => handleDateChange(e.currentTarget.value)}
                                             value={state.dob}
                                             format="DD/MM/YYYY"
                                             disabled={state.dob_disable}
                                             InputProps={{
                                                  endAdornment: (
                                                       <InputAdornment position="end" style={{ cursor: "pointer" }}>
                                                            {state.dob_disable ?
                                                                 <EditRoundedIcon onClick={submitEditDob} />
                                                                 :
                                                                 <Stack direction="row" spacing={1}>
                                                                      <ClearRoundedIcon onClick={submitEditDob} />
                                                                      <CheckRoundedIcon onClick={updateDob} />
                                                                 </Stack>
                                                            }
                                                       </InputAdornment>
                                                  ),
                                             }}
                                        /> */}
                                        <Stack
                                             direction="row"
                                             className={state.dob_disable ? "custom-datepicker" : "custom-datepicker-edit"}
                                             spacing={3}
                                             display="flex"
                                             alignItems="center"
                                             justifyContent="space-between"
                                        >
                                             <DatePicker
                                                  label="Date of Birth"
                                                  // value={new Date(state.dob)}
                                                  value={state.dob ? new Date(state.dob) : state.dob}
                                                  onChange={handleDateChange}
                                                  format="dd/MM/yyyy"
                                                  disabled={state.dob_disable}
                                             />
                                             <Grid>
                                                  <Stack direction="row" spacing={1}>
                                                       {state.dob_disable ?
                                                            <EditRoundedIcon sx={{ cursor: "pointer" }} onClick={submitEditDob} />
                                                            :
                                                            <Stack direction="row" spacing={1}>
                                                                 <ClearRoundedIcon color="error" sx={{ cursor: "pointer" }} onClick={submitEditDob} />
                                                                 <CheckRoundedIcon color="success" sx={{ cursor: "pointer" }} onClick={updateDob} />
                                                            </Stack>
                                                       }
                                                  </Stack>
                                             </Grid>
                                        </Stack>
                                   </Grid>
                              </Grid>
                              {role !== "sale" && (
                                   <Grid container justifyContent={"flex-end"} mt={2}>
                                        {disabled_textfield ? (
                                             <>
                                                  <Button
                                                       variant="contained"
                                                       style={{ backgroundColor: "#4589C6" }}
                                                       sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40, ml: 2 }}
                                                       onClick={() => submitEdit()}
                                                  >
                                                       {language === "th" ? "แก้ไข" : "Edit"}
                                                  </Button>
                                             </>
                                        ) : (
                                             <Button
                                                  variant="contained"
                                                  style={{ backgroundColor: "#4589C6" }}
                                                  sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40, ml: 2 }}
                                                  onClick={() => submitSave()}
                                             >
                                                  {language === "th" ? "บันทึก" : "Save"}
                                             </Button>
                                        )}
                                   </Grid>
                              )}
                         </CardContent>
                    </Card>
               </Grid>
          </Grid >
     );
};

const useStyles = makeStyles({
     fontActive: {
          fontSize: 15,
          color: "#fff",
          padding: 8,
          borderRadius: 10,
     },
     fontInvite: {
          fontSize: 15,
          color: "#fff",
          padding: 8,
          borderRadius: 10,
     },
});

export default ProfileDetailCard;
