import { Container } from "@material-ui/core";
import { headerTable } from "functions/Static";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GETUSERBYSALE, POST } from "service";
import swal from "sweetalert2";
import ManagerMemberCard from "./components/ManagerMemberCard";

export class ManagerMember extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: localStorage.getItem("language"),
            data: [],
            page: 1,
            profile_name: this.props.location.state.username
        };
    }
    componentDidMount = () => {
        this.getUserBySale();
    };

    getUserBySale = async () => {
        this.setState({ loading: true });
        try {
            let res = await POST(GETUSERBYSALE, { admin_id: this.props.match.params.id });
            if (res.success) {
                this.setState({ data: res.result })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };

    render() {
        const { language, data, page, profile_name } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <ManagerMemberCard
                    language={language}
                    data={data}
                    head={headerTable.managerMemberList}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    profile_name={profile_name}
                />
            </Container>
        );
    }
}

export default withRouter(ManagerMember);
