import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";

import { palette } from "./palette";
import { typography_set } from "./typography";
import { overrides } from "./overrides";

import anakotmaiMediumEot from "./webfont/anakotmai-medium.eot";
import anakotmaiMediumWoff from "./webfont/anakotmai-medium.woff";
import anakotmaiMediumWoff2 from "./webfont/anakotmai-medium.woff2";

import anakotmailightEot from "./webfont/anakotmai-light.eot";
import anakotmailightWoff from "./webfont/anakotmai-light.woff";
import anakotmailightWoff2 from "./webfont/anakotmai-light.woff2";

const theme = createMuiTheme({
     components: {
          MuiCssBaseline: {
               styleOverrides: `
              @font-face {
                font-family: 'Anakotmai';
                font-style: normal;
                font-weight: 600;
                font-size: 18;
                src: "url(${anakotmaiMediumEot}) format('embedded-opentype'), url(${anakotmaiMediumWoff}) format('woff'), url(${anakotmaiMediumWoff2}) format('woff2')";
              }, 
              @font-face {
               font-family: 'Anakotmai';
               font-style: normal;
               font-weight: 400;
               font-size: 18;
               src: "url(${anakotmailightEot}) format('embedded-opentype'), url(${anakotmailightWoff}) format('woff'), url(${anakotmailightWoff2}) format('woff2')";
             }
            `,
          },
     },
     palette,
     typography: {
          typography: typography_set,
          fontFamily: "Anakotmai",
     },
     overrides,
     zIndex: {
          appBar: 1200,
          drawer: 1100,
     },
     breakpoints: {
          values: {
               xs: 0,
               sm: 600,
               md: 900,
               lg: 1200,
               xl: 1600,
          },
     },
});

export default theme;
