import React, { useState } from "react";
import { withRouter } from "react-router-dom";
// import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Stack, Button, TextField } from "@material-ui/core";
import { GridTextField } from "../../../components/index";
import { textFiled, headerTable } from "../../../functions/Static";
import GridTable from "./GridTable";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CsvDownload from "react-csv-downloader";

const ListBList = (props) => {
     //  const classes = useStyles();
     const { data, language, page, handleChangePage, handleSearch, state, submitSearch, onClicView, onClickGenerate, generate, url, onClickCopy, count_page, data_csv, getIBExport, onClicViewNest } = props;
     // const count = Math.ceil(data.length > 0 && data.length / 10);
     const count = Math.ceil(count_page > 0 && count_page / 10);
     const setPage = page - 1;
     // const dataList = data.slice(setPage * 10, page * 10);
     const [copied, setCopied] = useState(false);
     const role = localStorage.getItem("role");
     const columns = [
          {
               id: 'user_id',
               displayName: "User Id"
          },
          {
               id: 'ib_id',
               displayName: "User id"
          },
          {
               id: 'manager',
               displayName: "Manager"
          },
          {
               id: 'email',
               displayName: "Email"
          },
          {
               id: 'phone',
               displayName: "Mobile"
          },
          {
               id: 'people',
               displayName: "Number of members"
          },
          {
               id: 'admin_name',
               displayName: "Admin"
          },
     ];
     return (
          <Grid container spacing={5} justifyContent="center" alignItems="center">
               <Grid item xs={12}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>IB List</Typography>
                    <Stack direction="row" alignItems="center" mt={2}>
                         <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2 }}>Search:</Typography>
                         <Grid container sx={{ width: "40vw", mr: 2 }}>
                              <GridTextField
                                   data={textFiled.search_text}
                                   state={state}
                                   handleChange={handleSearch}
                                   onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                             submitSearch();
                                        }
                                   }}
                              />
                         </Grid>
                         <Button
                              variant="contained"
                              style={{ backgroundColor: "#4589C6" }}
                              //  className="btn-style"
                              sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                              onClick={submitSearch}
                         >
                              {language === "th" ? "ค้นหา" : "Search"}
                         </Button>
                    </Stack>
                    <Grid item xs={12}>
                         {role !== "admin" && (
                              <Button
                                   variant="contained"
                                   // color="error"
                                   style={{ backgroundColor: "#4589C6" }}
                                   className="btn-style"
                                   sx={{ maxWidth: 250, fontSize: 16, width: "100%", height: 40, marginTop: 2, marginBottom: 2 }}
                                   onClick={onClickGenerate}
                              >
                                   Generate IB URL
                              </Button>
                         )}

                         {generate && (
                              <Grid direction="row" container alignItems="center" md={10} xs={12} spacing={2}>
                                   <Grid item xs={7}>
                                        <TextField size="small" value={url} variant="outlined" fullWidth />
                                   </Grid>
                                   <CopyToClipboard text={state.url} onCopy={() => setCopied(true)}>
                                        <Grid item xs={5} container direction="row">
                                             <Button variant="contained" color="error" sx={{ fontSize: 16, height: 40, marginTop: 2, marginBottom: 2 }}>
                                                  Copy
                                             </Button>
                                             {copied && (
                                                  <Typography sx={{ fontSize: 14, color: "#000", fontWeight: 400, alignSelf: "center", marginLeft: 1 }}>
                                                       {language === "th" ? "คัดลอกลิงค์แล้ว" : "Link is copied"}
                                                  </Typography>
                                             )}
                                        </Grid>
                                   </CopyToClipboard>
                              </Grid>
                         )}
                    </Grid>
               </Grid>
               <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
                    <CsvDownload
                         style={{ color: "#fff", textDecoration: "none" }}
                         filename={`IB_Report_` + new Date().toLocaleString()}
                         extension='.csv'
                         columns={columns}
                         datas={getIBExport}
                    >
                         <Button
                              variant="contained"
                              style={{ backgroundColor: "#4589C6" }}
                              sx={{ maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                         >
                              export data
                         </Button>
                    </CsvDownload>
               </Grid>
               <Grid item xs={12}>
                    <GridTable head={headerTable.iblist} body={data} count={count} page={page} handleChangePage={handleChangePage} onClicView={onClicView} onClicViewNest={onClicViewNest} />
               </Grid>
          </Grid>
     );
};
// const useStyles = makeStyles((theme) => ({
//      imgStyle: {
//           height: 230,
//           [theme.breakpoints.up("sm")]: {
//                objectFit: "contain",
//                height: 340,
//           },
//           [theme.breakpoints.up("md")]: {
//                objectFit: "cover",
//                height: 340,
//           },
//           [theme.breakpoints.up("lg")]: {
//                objectFit: "contain",
//                height: 340,
//           },
//      },
//      borderSolid: {
//           width: "100%",
//           border: "solid 1px #fc0316",
//           background: "#fc0316",
//           [theme.breakpoints.up("md")]: {
//                width: 100,
//           },
//      },
//      fontStyleTitle: {
//           fontSize: 24,
//           color: "#000",
//           marginTop: 15,
//           fontWeight: 600,
//           paddingLeft: 16,
//           paddingRight: 16,
//           [theme.breakpoints.up("md")]: {
//                paddingLeft: 0,
//                paddingRight: 0,
//                marginTop: 0,
//           },
//      },
//      fontStyleDetail: {
//           fontSize: 13,
//           color: "#000",
//           textAlign: "justify",
//           marginTop: 24,
//           paddingLeft: 16,
//           paddingRight: 16,
//           [theme.breakpoints.up("md")]: {
//                paddingLeft: 0,
//                paddingRight: 0,
//                fontSize: 18,
//           },
//      },
//      btnStyle: {
//           textAlign: "right",
//           marginTop: 3,
//           [theme.breakpoints.up("md")]: {
//                marginTop: 16,
//                textAlign: "left",
//           },
//      },
// }));
export default withRouter(ListBList);
