import React, { useState } from "react";
import { withRouter } from "react-router-dom";
// import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Stack, Button, TextField, Alert, Snackbar, Box } from "@material-ui/core";
import { GridTextField } from "../../../components/index";
import { textFiled, headerTable } from "../../../functions/Static";
import GridTable from "./GridTable";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CsvDownload from "react-csv-downloader";

const ListIBRebateList = (props) => {
     //  const classes = useStyles();
     const { data, language, page, handleChangePage, handleSearch, state, submitSearch, onClickView, onClickGenerate, generate, url, count_page, onClickCampaign, getIBCampaign, downloadReport, download_success, handleCloseAlert } = props;
     // const count = Math.ceil(data.length > 0 && data.length / 10);
     const count = Math.ceil(count_page > 0 && count_page / 10);
     const setPage = page - 1;
     // const dataList = data.slice(setPage * 10, page * 10);
     const [copied, setCopied] = useState(false);
     const role = localStorage.getItem("role");
     const columns = [
          {
               id: 'user_id',
               displayName: "User Id"
          },
          {
               id: 'username',
               displayName: "Name"
          },
          {
               id: 'email',
               displayName: "Email"
          },
          {
               id: 'people',
               displayName: "Number of members"
          },
          {
               id: 'admin_name',
               displayName: "Admin"
          },
          {
               id: 'number_campaign',
               displayName: "Number of campaign"
          }
     ];
     return (
          <Grid container spacing={5} justifyContent="center" alignItems="center">
               <Grid item xs={12}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>IB Rebate List</Typography>
                    <Stack direction="row" alignItems="center" mt={2}>
                         <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2 }}>Search:</Typography>
                         <Grid container sx={{ width: "40vw", mr: 2 }}>
                              <GridTextField
                                   data={textFiled.search_text}
                                   state={state}
                                   handleChange={handleSearch}
                                   onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                             submitSearch();
                                        }
                                   }}
                              />
                         </Grid>
                         <Button
                              variant="contained"
                              style={{ backgroundColor: "#4589C6" }}
                              //  className="btn-style"
                              sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                              onClick={submitSearch}
                         >
                              {language === "th" ? "ค้นหา" : "Search"}
                         </Button>
                    </Stack>
                    {/* <Grid item xs={12}>
                         {role !== "admin" && (
                              <Button
                                   variant="contained"
                                   // color="error"
                                   style={{ backgroundColor: "#4589C6" }}
                                   className="btn-style"
                                   sx={{ maxWidth: 250, fontSize: 16, width: "100%", height: 40, marginTop: 2, marginBottom: 2 }}
                                   onClick={onClickGenerate}
                              >
                                   Generate IB URL
                              </Button>
                         )}

                         {generate && (
                              <Grid direction="row" container alignItems="center" md={10} xs={12} spacing={2}>
                                   <Grid item xs={7}>
                                        <TextField size="small" value={url} variant="outlined" fullWidth />
                                   </Grid>
                                   <CopyToClipboard text={state.url} onCopy={() => setCopied(true)}>
                                        <Grid item xs={5} container direction="row">
                                             <Button variant="contained" color="error" sx={{ fontSize: 16, height: 40, marginTop: 2, marginBottom: 2 }}>
                                                  Copy
                                             </Button>
                                             {copied && (
                                                  <Typography sx={{ fontSize: 14, color: "#000", fontWeight: 400, alignSelf: "center", marginLeft: 1 }}>
                                                       {language === "th" ? "คัดลอกลิงค์แล้ว" : "Link is copied"}
                                                  </Typography>
                                             )}
                                        </Grid>
                                   </CopyToClipboard>
                              </Grid>
                         )}
                    </Grid> */}
               </Grid>
               <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
                    <Stack direction="row" spacing={3} width="100%" display="flex" justifyContent="flex-end">
                         <CsvDownload
                              style={{ color: "#fff", textDecoration: "none" }}
                              filename={`IB_Campaign_` + new Date().toLocaleDateString('en-GB')}
                              extension='.csv'
                              columns={columns}
                              datas={getIBCampaign}
                         >
                              <Button
                                   variant="contained"
                                   style={{ backgroundColor: "#4589C6" }}
                                   sx={{ maxWidth: 200, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                              >
                                   export campaign
                              </Button>
                         </CsvDownload>
                         <Box width="100%" maxWidth={200}>
                              <Button
                                   variant="contained"
                                   style={{ backgroundColor: "#4589C6" }}
                                   sx={{ maxWidth: 200, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                                   onClick={downloadReport}
                              >
                                   export ib rebate
                              </Button>
                         </Box>
                    </Stack>

               </Grid>
               <Grid item xs={12}>
                    <GridTable head={headerTable.ib_rebate_list} body={data} count={count} page={page} handleChangePage={handleChangePage} onClickView={onClickView} onClickCampaign={onClickCampaign} />
               </Grid>
               <Snackbar open={download_success} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert
                         onClose={handleCloseAlert}
                         severity="success"
                         variant="filled"
                         sx={{ width: '100%' }}
                    >
                         Download report is a success.
                    </Alert>
               </Snackbar>
          </Grid>
     );
};

export default withRouter(ListIBRebateList);
