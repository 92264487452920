import { Grid, Typography, TableContainer, Table, Button, Pagination, Stack, TableCell, TableHead, TableRow, TableBody } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import DatePicker from "react-date-picker";
import { tofieds } from "functions/Functions";
const theme = createMuiTheme();

const GridTableIBMember = (props) => {
     const history = useHistory();
     const classes = useStyles();

     const onClickView = (id) => {
          // this.props.history.push(`/ibdetail/${id}`);
          // props.history.push(`/editrebate/${id}`);
          history.push(`/editrebate/${props.userId}/${id}`);
     };

     const role = localStorage.getItem("role");
     const nav_length = props.nav_sub_ib.length;

     return (
          <Grid className="table-btn">
               <Grid item container direction={"row"} borderBottom={"2px solid #EBEBEB"} paddingBottom="10px">
                    <Grid item xs={12}>
                         <Stack direction="row" display="flex" alignItems="center" >
                              {props.nav_sub_ib.map((e, i) =>
                                   <Typography key={i + 1} className={classes.fontStyleNav} sx={{ cursor: "pointer" }} onClick={() => props.onClickNetworkChildBack(e.user_id)}>
                                        {e.name} {e.lastname}&nbsp;{nav_length - (i + 1) > 0 && `>`}&nbsp;
                                   </Typography>
                              )}
                         </Stack>
                         <Typography mt={1} className={classes.fontStyleTitle}>Sub IB</Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
                         <Stack direction="row" alignItems="center" mt={2}>
                              <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
                                   Search
                              </Typography>
                              <Grid container xs={12} md={12} lg={10} sx={{ mr: 2 }} spacing={1}>
                                   <Grid item xs={12} md={3}>
                                        <DatePicker className={"date-picker"} onChange={props.handleDateStartChange} value={props.state.date_start} format="dd/MM/yyyy" />
                                   </Grid>
                                   <Grid item xs={12} md={3}>
                                        <DatePicker className={"date-picker"} onChange={props.handleDateEndChange} value={props.state.date_end} format="dd/MM/yyyy" />
                                   </Grid>
                                   <Grid item xs={12} md={3}>
                                        <Button
                                             variant="contained"
                                             style={{ backgroundColor: "#4589C6" }}
                                             //  className="btn-style"
                                             sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "none", md: "block" } }}
                                             onClick={props.submitSearch}
                                             onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                       props.submitSearch();
                                                  }
                                             }}
                                        >
                                             Search
                                        </Button>
                                   </Grid>
                              </Grid>
                              <Button
                                   variant="contained"
                                   color="error"
                                   sx={{ maxWidth: 50, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "block", md: "none" } }}
                                   onClick={props.submitSearch}
                              >
                                   <SearchIcon />
                              </Button>
                         </Stack>
                    </Grid> */}
                    {/* <Grid item xs={6}> */}
                    {/* <Typography className={classes.fontStyleTitle}>Name</Typography> */}
                    {/* </Grid> */}
               </Grid>
               <TableContainer>
                    <Table className={classes.table}>
                         <TableHead>
                              <TableRow>
                                   {props.head.map((el, i) => {
                                        return (
                                             <TableCell key={i} align={el.align || "left"}>
                                                  <Typography noWrap>{el.label}</Typography>
                                             </TableCell>
                                        );
                                   })}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {props.body.filter((e) => Number(e.user_id) !== Number(props.userId)).map((el, i) => {
                                   return (
                                        <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                             <TableCell >
                                                  {el.count_team > 0 ?
                                                       <Typography noWrap sx={{ fontSize: 16, color: "#707070", cursor: "pointer" }} onClick={() => props.onClickNetworkChild(el.user_id, el.name, el.lastname)}>
                                                            {el.name} {el.lastname} ({el.count_team})
                                                       </Typography>
                                                       :
                                                       <Typography noWrap sx={{ fontSize: 16, color: "#707070" }} >
                                                            {el.name} {el.lastname} ({el.count_team})
                                                       </Typography>
                                                  }
                                             </TableCell>
                                             <TableCell align="left">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.email}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {tofieds(el.my_lot) || "0.00"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {tofieds(el.commission) || "0.00"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {tofieds(el.deposit) || "0.00"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {tofieds(el.withdraw) || "0.00"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {tofieds(Number(el.net_deposit)) || "0.00"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {tofieds(Number(el.net_deposit_team)) || "0.00"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {tofieds(el.team_deposit) || "0.00"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {tofieds(el.team_lot) || "0.00"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {tofieds(el.agent_fee) || "0.00"}
                                                  </Typography>
                                             </TableCell>
                                             {/* {(role === "admin" && el.is_head) &&
                                                  (
                                                       <TableCell align="center">
                                                            <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                                 <Button
                                                                      variant="contained"
                                                                      onClick={() => {
                                                                           onClickView(el.user_id);
                                                                      }}
                                                                 >
                                                                      Edit Rebate
                                                                 </Button>
                                                            </Typography>
                                                       </TableCell>
                                                  )
                                             } */}
                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               <Grid container justifyContent="center" mt={5}>
                    {props.body.length === 0 && (
                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                              {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                         </Typography>
                    )}
                    {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
               </Grid>
          </Grid>
          // <TableContainer>
          //      <Table className={classes.table} aria-label="caption table">
          //           <Grid item container direction={"row"} borderBottom={"2px solid #EBEBEB"} paddingBottom="10px">
          //                <Grid item xs={12}>
          //                     <Stack direction="row" display="flex" alignItems="center" >
          //                          {props.nav_sub_ib.map((e, i) =>
          //                               <Typography key={i + 1} className={classes.fontStyleNav} sx={{ cursor: "pointer" }} onClick={() => props.onClickNetworkChildBack(e.user_id)}>
          //                                    {e.name} {e.lastname}&nbsp;{nav_length - (i + 1) > 0 && `>`}&nbsp;
          //                               </Typography>
          //                          )}
          //                     </Stack>
          //                     <Typography mt={1} className={classes.fontStyleTitle}>Sub IB</Typography>
          //                </Grid>
          //                <Grid item xs={12}>
          //                     <Stack direction="row" alignItems="center" mt={2}>
          //                          <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
          //                               Search
          //                          </Typography>
          //                          <Grid container xs={12} md={12} lg={10} sx={{ mr: 2 }} spacing={1}>
          //                               <Grid item xs={12} md={3}>
          //                                    <DatePicker className={"date-picker"} onChange={props.handleDateStartChange} value={props.state.date_start} format="dd/MM/yyyy" />
          //                               </Grid>
          //                               <Grid item xs={12} md={3}>
          //                                    <DatePicker className={"date-picker"} onChange={props.handleDateEndChange} value={props.state.date_end} format="dd/MM/yyyy" />
          //                               </Grid>
          //                               <Grid item xs={12} md={3}>
          //                                    <Button
          //                                         variant="contained"
          //                                         style={{ backgroundColor: "#4589C6" }}
          //                                         //  className="btn-style"
          //                                         sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "none", md: "block" } }}
          //                                         onClick={props.submitSearch}
          //                                         onKeyDown={(e) => {
          //                                              if (e.key === "Enter") {
          //                                                   props.submitSearch();
          //                                              }
          //                                         }}
          //                                    >
          //                                         Search
          //                                    </Button>
          //                               </Grid>
          //                          </Grid>
          //                          <Button
          //                               variant="contained"
          //                               color="error"
          //                               sx={{ maxWidth: 50, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "block", md: "none" } }}
          //                               onClick={props.submitSearch}
          //                          >
          //                               <SearchIcon />
          //                          </Button>
          //                     </Stack>
          //                </Grid>
          //                {/* <Grid item xs={6}> */}
          //                {/* <Typography className={classes.fontStyleTitle}>Name</Typography> */}
          //                {/* </Grid> */}
          //           </Grid>
          //           {data_length > 0 ?
          //                <>
          //                     {props.data?.map((el, i) => (
          //                          <Grid key={i} container direction={"row"} spacing={1} display="flex" alignItems="center" my="10px">
          //                               {el.count > 0 ?
          //                                    <Grid item xs={8}>
          //                                         <Typography className={classes.fontStyleDetail} sx={{ cursor: "pointer" }} onClick={() => props.onClickNetworkChild(el.user_id, el.name, el.lastname)}>
          //                                              {el.name} {el.lastname} {el.phone} {el.mt4}{" "}( {el.count} )
          //                                         </Typography>
          //                                    </Grid>
          //                                    :
          //                                    <Grid item xs={8}>
          //                                         <Typography className={classes.fontStyleDetail} >
          //                                              {el.name} {el.lastname} {el.phone} {el.mt4}{" "}( {el.count} )
          //                                         </Typography>
          //                                    </Grid>}

          //                               {role !== "normal" && (
          //                                    <Grid item xs={4}>
          //                                         {nav_length === 1 && (
          //                                              <Button
          //                                                   variant="contained"
          //                                                   onClick={() => {
          //                                                        onClickView(el.user_id);
          //                                                   }}
          //                                              >
          //                                                   Edit Rebate
          //                                              </Button>
          //                                         )}
          //                                    </Grid>
          //                               )}
          //                          </Grid>
          //                     ))}
          //                </>
          //                :
          //                <Grid mt={5} item xs={12}>
          //                     <Typography className={classes.fontStyle}>*ไม่มีข้อมูลแสดง*</Typography>
          //                </Grid>
          //           }

          //           {/* {props.data.map((el, i) => (
          //                <Grid key={i} item container direction={"row"} spacing={1} borderBottom={"1px solid #EBEBEB"} marginBottom="20px" paddingBottom="20px">
          //                     <Grid item xs={6}>
          //                          <Typography className={classes.fontStyleDetail} sx={{ fontWeight: 600 }}>
          //                               {i + 1}
          //                          </Typography>
          //                     </Grid>
          //                     {el.map((ele, ind) => (
          //                          <>
          //                               <Grid key={ind} item xs={8}>
          //                                    <Typography className={classes.fontStyleDetail}>
          //                                         {ele.name} {ele.lastname} {ele.phone} {ele.mt4}{" "}
          //                                    </Typography>
          //                               </Grid>
          //                               {role !== "normal" && (
          //                                    <Grid item xs={4}>
          //                                         {i === 0 && (
          //                                              <Button
          //                                                   variant="contained"
          //                                                   onClick={() => {
          //                                                        onClickView(ele.user_id);
          //                                                   }}
          //                                              >
          //                                                   Edit Rebate
          //                                              </Button>
          //                                         )}
          //                                    </Grid>
          //                               )}

          //                          </>
          //                     ))}
          //                </Grid>
          //           ))} */}
          //      </Table>
          // </TableContainer>
     );
};
const useStyles = makeStyles(() => ({
     table: {
          minWidth: 400,
     },
     fontStyleTitle: {
          fontSize: 18,
          fontWeight: 600,
          color: "#000",
     },
     fontStyleNav: {
          fontSize: 13,
          fontWeight: "bold",
          color: "#000",
          textAlign: "center",
          [theme.breakpoints.up("md")]: {
               fontSize: 18,
          },
     },
     fontStyleDetail: {
          fontSize: 13,
          color: "#000",
          // textAlign: "justify",
          [theme.breakpoints.up("md")]: {
               fontSize: 18,
          },
          marginTop: 10,
          marginBottom: 10,
     },
     fontStyle: {
          fontSize: 13,
          color: "#000",
          [theme.breakpoints.up("md")]: {
               fontSize: 18,
          },
          display: "flex",
          justifyContent: "center",
     },
}));
export default GridTableIBMember;
