import { Grid, Typography } from "@material-ui/core";
import { headerTable } from "functions/Static";
import TableListSupport from "./TableListSupport";

const CardSupport = (props) => {
     const { language, data_table, handleChangePage, page, onClickView } = props;
     const count = Math.ceil(data_table.length > 0 && data_table.length / 10);
     const setPage = page - 1;
     const dataList = data_table.slice(setPage * 10, page * 10);
     return (
          <Grid container width={"100%"} spacing={2}>
               <Grid item xs={12}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>{language === "th" ? "ซัพพอร์ต" : "Support"}</Typography>
               </Grid>
               <Grid item xs={12}>
                    <TableListSupport head={headerTable.supporlist} body={dataList} count={count} page={page} handleChangePage={handleChangePage} onClickView={onClickView} />
               </Grid>
          </Grid>
     );
};

export default CardSupport;
