import React, { useState } from "react";
import { Typography, Grid, TextField, MenuItem, TextareaAutosize, CardMedia, IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
// import { PersonOutlineOutlined } from "@material-ui/icons";

const GridTextField = (props) => {


     const [showPassword, setShowPassword] = useState(false);

     const handleClickShowPassword = (name) => {
          setShowPassword((prev) => ({ ...prev, [name]: !prev[name] }));
     };
     const handleMouseDownPassword = (event) => {
          event.preventDefault();
     };

     const handleMouseUpPassword = (event) => {
          event.preventDefault();
     };

     return props.data.map((e, i) => (
          <Grid
               key={i}
               item
               xs={e.xs || false}
               sm={e.sm || false}
               md={e.md || false}
               className={
                    e.className ? (e.className === props.state[e.name] ? e.classNameGrid + " fonts-color" : e.classNameGrid) : e.classNameGrid || ""
               }
               container
               justifyContent="center"
               alignItems="center"
          >
               {e.typography && (
                    <Grid item sm={4} textAlign="left" sx={{ display: { xs: "none", sm: "block" } }}>
                         <Typography>{(props.language === "th" ? e.typography_th || e.typography : e.typography) || ""}</Typography>
                    </Grid>
               )}
               {e.type !== "textarea" ? (
                    <Grid item xs={12} sm={e.typography ? 8 : false}>
                         <TextField
                              disabled={e.disable ? e.disable : props.disabled}
                              error={props.check && props.check[e.name] ? props.check[e.name].err : false}
                              helperText={
                                   props.check && props.check[e.name] && props.check[e.name].err
                                        ? props.check[e.name].label
                                        : props.language === "th"
                                             ? e.textPlaceholder_th
                                                  ? e.textPlaceholder_th
                                                  : ""
                                             : e.textPlaceholder_en
                                                  ? e.textPlaceholder_en
                                                  : ""
                              }
                              placeholder={(props.language === "th" ? e.placeholder_th || e.placeholder : e.placeholder) || ""}
                              className={e.classNameText || ""}
                              label={e.label || ""}
                              name={e.name || ""}
                              // type={e.type || ""}
                              type={e.type === 'password' ? showPassword[e.name] ? 'text' : 'password' : e.type || ""}
                              value={props.state[e.name] || ""}
                              onChange={props.handleChange}
                              select={e.select}
                              fullWidth
                              variant="outlined"
                              onKeyDown={props.onKeyDown}
                              autoComplete="off"
                              InputProps={{
                                   startAdornment: e.icon && (
                                        <CardMedia
                                             sx={{ height: 20, width: "auto", objectFit: "contain", mr: 2, mt: -0.25 }}
                                             component="img"
                                             alt="Logo"
                                             image={e.icon}
                                        />
                                   ),
                                   endAdornment: e.type === "password" && (
                                        <InputAdornment position="end">
                                             <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={() => handleClickShowPassword(e.name)}
                                                  onMouseDown={handleMouseDownPassword}
                                                  onMouseUp={handleMouseUpPassword}
                                                  edge="end"
                                             >
                                                  {showPassword[e.name] ? <VisibilityOff /> : <Visibility />}
                                             </IconButton>
                                        </InputAdornment>)
                              }}
                         >
                              {e.select &&
                                   props[e.selectname] &&
                                   props[e.selectname].map((el) => (
                                        <MenuItem
                                             key={el.value}
                                             value={el.value}
                                             selected={el.value}
                                             hidden={el.hidden ? el.hidden : false}
                                             disabled={el.disabled ? el.disabled : false}
                                        >
                                             {el.label}
                                        </MenuItem>
                                   ))}
                         </TextField>
                    </Grid>
               ) : (
                    <Grid item xs={12}>
                         <TextareaAutosize
                              minRows={5}
                              name={e.name || ""}
                              value={props.state[e.name] || ""}
                              onChange={props.handleChange}
                              placeholder={e.placeholder ? e.placeholder : ""}
                              style={{
                                   borderColor: props.check && props.check[e.name] && props.check[e.name].err ? "red" : "transparent",
                              }}
                         />
                    </Grid>
               )}
          </Grid >
     ));
};

export default GridTextField;
