import { Container, Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { POST, NETWORKBYID, NETWORKNESTBYID } from "service";
import swal from "sweetalert2";
import TableNetwork from "./TableNetwork";
import TableNetworkNest from "./TableNetworkNest";
import { headerTable } from "functions/Static";

class NetworkById extends Component {
     constructor(props) {
          super(props);

          this.state = {
               dataId: props.dataId,
               data: [],
               data_network_nest: [],
          };
     }

     componentDidMount() {
          // this.getNetwork();
          this.getNetworkNest();
     }

     getNetwork = async () => {
          const { data } = this.state;
          try {
               this.setState({ loading: true });
               let network = await POST(NETWORKBYID, { user_id: this.state.dataId });
               if (network.success) {
                    let dataNw = network.result;
                    this.setState({ data: dataNw });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: network.message, icon: "warning",
                         // imer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     getNetworkNest = async () => {
          try {
               this.setState({ loading: true });
               let res = await POST(NETWORKNESTBYID, { user_id: this.state.dataId });
               if (res.success) {
                    // console.log(res);
                    this.setState({ data_network_nest: res.result })
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    showConfirmButton: true
               });
          }
     };

     render() {
          const language = localStorage.getItem("language");
          const { data, data_network_nest } = this.state;
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    {/* <Grid item xs={12} mt={5} mb={5}>
                         <Typography fontWeight={600}>Network</Typography>
                    </Grid> */}
                    {/* <TableNetwork data={data} /> */}
                    <Grid xs={12} item>
                         <TableNetworkNest
                              data={data_network_nest}
                              state={this.state}
                              body={data_network_nest}
                              header={headerTable.network_list_nest}
                              language={language}
                         />
                    </Grid>
               </Container>
          );
     }
}

export default NetworkById;
