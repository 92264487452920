import { Grid, Typography, Button, Paper, Stack } from "@material-ui/core";
import GridTableIB from "./GridTableIB";
import GridTableNetwork from "./GridTableNetwork";
import DetailTaps from "../DetailTaps/DetailTaps";
import GridTableIBMember from "./GridTableIBMember";
import GridPaperIB from "./GridPaperIB";
import RebateCard from "pages/Rebate/components/RebateCard";
import { GridTextField } from "components";
import { textFiled } from "functions/Static";
import { headerTable } from "functions/Static";
import DatePicker from "react-date-picker";
import SearchIcon from "@material-ui/icons/Search";

const TableIB = (props) => {
     const role = localStorage.getItem("role");
     // console.log('props',props)

     const count = Math.ceil(props.count_page > 0 && props.count_page / 5);
     const setPage = props.page - 1;
     // const dataList = props.data_network.slice(setPage * 10, props.page * 10);

     return (
          <Grid container spacing={2}>
               <Grid xs={12} item>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>IB Network</Typography>
               </Grid>
               {props.data_header_profile.map((e, i) =>
                    <Grid key={i + 1} item xs={12}>
                         <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
                              {e.name} {e.lastname} ( {props.data_header.count_team ? props.data_header.count_team : 0} )
                         </Typography>
                    </Grid>
               )}
               <Grid item xs={12} >
                    <Stack direction="row" display="flex" alignItems="center" mt={2}>
                         <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
                              Search :
                         </Typography>
                         <Grid container xs={12} md={12} lg={10} sx={{ mr: 2 }} spacing={1} display="flex" alignItems="center">
                              <Grid item xs={12} md={3}>
                                   <DatePicker className={"date-picker"} onChange={props.handleDateStartChange} value={props.state.date_start} format="dd/MM/yyyy" />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                   <DatePicker className={"date-picker"} onChange={props.handleDateEndChange} value={props.state.date_end} format="dd/MM/yyyy" />
                              </Grid>
                              {/* <Grid item xs={6} md={3} sx={{ width: "40vw" }}>
                                   <GridTextField
                                        data={textFiled.search_text}
                                        state={props.state}
                                        handleChange={props.handleSearch}
                                   />
                              </Grid> */}
                              <Grid item xs={6} md={3}>
                                   <Button
                                        variant="contained"
                                        style={{ backgroundColor: "#4589C6" }}
                                        //  className="btn-style"
                                        sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "none", md: "block" } }}
                                        onClick={() => props.submitSearch()}
                                        onKeyDown={(e) => {
                                             if (e.key === "Enter") {
                                                  props.submitSearch();
                                             }
                                        }}
                                   >
                                        Search
                                   </Button>
                              </Grid>
                         </Grid>
                         <Button
                              variant="contained"
                              color="error"
                              sx={{ maxWidth: 50, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "block", md: "none" } }}
                              onClick={() => props.submitSearch()}
                         >
                              <SearchIcon />
                         </Button>
                    </Stack>
               </Grid>
               <Grid item xs={12}>
                    {/* <GridPaperIB data={props.data_header} /> */}
                    <GridPaperIB data={props.data_header} header={headerTable.ib_child_list_header} />
               </Grid>
               {/* <Grid container justifyContent={"space-between"} item>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #fc0316" }}>Manager</Typography>
                    {props.edit_disabled_manager && (
                         <Button className="btn-style" variant="contained" color="error" sx={{ maxWidth: 120 }} onClick={props.onClickEditManager}>
                              Edit
                         </Button>
                    )}
               </Grid>
               <Grid xs={12} item>
                    <GridTableNetwork
                         name={props.name}
                         data={props.data_network}
                         state={props.state}
                         handleChangeText={props.handleChangeText}
                         dividend_text={props.dividend_text}
                         edit_disabled={props.edit_disabled_manager}
                    />
               </Grid>
               <Grid container justifyContent="center">
                    {!props.edit_disabled_manager && (
                         <Button className="btn-style" variant="contained" color="error" sx={{ maxWidth: 120 }} onClick={props.onClickSaveManager}>
                              Save
                         </Button>
                    )}
               </Grid> */}
               <DetailTaps setTap={props.setTap} />
               {/* {props.tap_value === 1 ? (
                    <>
                         <Grid container justifyContent={"space-between"} item>
                              <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>Rebate</Typography>
                         </Grid>
                         <Grid xs={12} item>
                              <RebateCard
                                   state={props.state}
                                   handleChangeTextRebate={props.handleChangeTextRebate}
                                   onClickAddRebate={props.onClickAddRebate}
                                   onClickSaveRebate={props.onClickSaveRebate}
                                   userId={props.userId}
                              />
                         </Grid>
                    </>
               ) :  */}
               {props.tap_value === 2 ? (
                    <>
                         <Grid container justifyContent={"space-between"} item>
                              <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>Network</Typography>
                         </Grid>
                         <Grid xs={12} item>
                              <GridTableIBMember
                                   data={props.data_network}
                                   nav_sub_ib={props.nav_sub_ib}
                                   count_network={props.count_network}
                                   state={props.state}
                                   handleChangeText={props.handleChangeText}
                                   dividend_text={props.dividend_text}
                                   tap_value={props.tap_value}
                                   edit_disabled={props.edit_disabled_ib}
                                   userId={props.userId}
                                   onClickNetworkChild={props.onClickNetworkChild}
                                   onClickNetworkChildBack={props.onClickNetworkChildBack}
                                   handleDateStartChange={props.handleDateStartChange}
                                   handleDateEndChange={props.handleDateEndChange}
                                   submitSearch={props.submitSearch}
                                   page={props.page}
                                   handleChangePage={props.handleChangePage}
                                   head={role === "admin" ? headerTable.ib_child_list_admin : headerTable.ib_child_list}
                                   body={props.data_network}
                                   count={count}
                              />
                         </Grid>
                    </>
               ) : props.tap_value === 3 ? (
                    <>
                         <Grid container justifyContent={"space-between"} item>
                              <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>Account</Typography>
                         </Grid>
                         <Grid item xs={12} container spacing={2} mb={5}>
                              <GridTextField data={textFiled.ib_account} state={props.state} handleChange={props.handleChangeText} />
                         </Grid>
                         <Grid item container justifyContent={"center"}>
                              <Button className="btn-style" variant="contained" style={{ backgroundColor: "#4589C6" }} sx={{ maxWidth: 120 }} onClick={props.onClickEditAccount}>
                                   Edit
                              </Button>
                         </Grid>
                         {/* <Grid container justifyContent={"space-between"} item>
                              <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #fc0316" }}>IB</Typography>
                              {props.edit_disabled_ib && role === "admin" && (
                                   <Button className="btn-style" variant="contained" color="error" sx={{ maxWidth: 120 }} onClick={props.onClickEditIB}>
                                        Edit
                                   </Button>
                              )}
                              {!props.edit_disabled_ib && role === "admin" && (
                                   <Button className="btn-style" variant="contained" color="error" sx={{ maxWidth: 120 }} onClick={props.onClickAddSymbol}>
                                        + Add
                                   </Button>
                              )}
                         </Grid>
                         <Grid xs={12} item>
                              <GridTableIB
                                   dataSub={props.dataSub}
                                   data={props.data_network}
                                   state={props.state}
                                   handleChangeText={props.handleChangeText}
                                   handleChangeTextSub={props.handleChangeTextSub}
                                   dividend_text={props.dividend_text}
                                   tap_value={props.tap_value}
                                   edit_disabled={props.edit_disabled_ib}
                                   symbol={props.symbol}
                              />
                         </Grid>
                         <Grid container justifyContent="center">
                              {!props.edit_disabled_ib && (
                                   <Button className="btn-style" variant="contained" color="error" sx={{ maxWidth: 120 }} onClick={props.onClickSaveIB}>
                                        Save
                                   </Button>
                              )}
                         </Grid> */}
                    </>
               ) : null}
          </Grid>
     );
};

export default TableIB;
