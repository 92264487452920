import { Container } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import moment from "moment";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { ACCOUNTTYPEAPI, EDITIBACCOUNTTYPE, EDITIBPERCENT, EDITMANAGERPERCENT, GET, GETIBACCOUNTTYPE, GETIBALL, GETIBHEADER, GETIBNETWORK, GETREBATE, IBBYID, IBSYMBOL, POST, GETIBSEARCH } from "service";
import swal from "sweetalert2";
import TableIB from "./TableIB/TableIB";

// const MockDataNetwork = [{ type: "Manager", account: "0000000000", name: "Name Lastname", dividend: "50" }];

const MockdataIB = [
     {
          type: "1st",
          dataarray: [
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
          ],
          dividend: "10",
     },
     {
          type: "2nd",
          dataarray: [
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
          ],
          dividend: "10",
     },
     {
          type: "3th",
          dataarray: [
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
          ],
          dividend: "10",
     },
     {
          type: "4th",
          dataarray: [
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
          ],
          dividend: "10",
     },
     {
          type: "5th",
          dataarray: [
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
          ],
          dividend: "10",
     },
];

class IBDetail extends Component {
     constructor(props) {
          super(props);

          this.state = {
               page: 1,
               IB: [],
               dividend_text: [
                    {
                         xs: 12,
                         classNameGrid: "pt-0",
                         classNameText: "textfield-css mt-2",
                         placeholder: "Dividend",
                         name: "dividend",
                    },
               ],
               dividend: 0,
               tap_value: 2,
               edit_disabled_manager: true,
               edit_disabled_ib: true,
               name: "",
               dataSub: [],
               network: [],
               loading: false,
               symbol: [],
               checkForex: false,
               checkGold: false,
               checkCrypto: false,
               checkIndex: false,
               checkOil: false,
               rebate: [],
               manager_id: null,
               dataTabel: [],
               accountType: null,
               nav_sub_ib: [],
               count_network: [],
               // date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
               date_start: new Date(),
               date_end: new Date(),
               data_header: [],
               data_header_profile: [],
               user_id: this.props.match.params.id,
               count_page: 0,
               search_text: '',
          };
     }

     componentDidMount = () => {
          this.getIBDetail();
          this.getNetwork();
          this.getIbSymbol();
          this.getRebate();
          this.getCountNetwork();
          this.getHeader()
     };

     getIBDetail = async () => {
          let { date_start, date_end } = this.state;
          let date_start_format = moment(date_start).format("YYYY-MM-DD");
          let date_end_format = moment(date_end).format("YYYY-MM-DD");

          try {
               this.setState({ loading: true });
               let IBDetail = await POST(IBBYID, { date_start: date_start_format, date_end: date_end_format, user_id: this.props.match.params.id });
               if (IBDetail.success) {
                    // this.getCountNetwork();
                    const sub_rebate = JSON.parse(IBDetail.result.ib.sub_rebate);
                    let dataSub = [];
                    let nav_sub_ib = [];
                    sub_rebate.forex.map((el, i) =>
                         dataSub.push({
                              forex: parseFloat(sub_rebate.forex[i]).toFixed(2) || 0.0,
                              gold: parseFloat(sub_rebate.gold).toFixed(2) || 0.0,
                              crypto: parseFloat(sub_rebate.crypto[i]).toFixed(2) || 0.0,
                              index: parseFloat(sub_rebate.index[i]).toFixed(2) || 0.0,
                              oil: parseFloat(sub_rebate.oil[i]).toFixed(2) || 0.0,
                         })
                    );
                    nav_sub_ib.push({
                         user_id: IBDetail.result.ib.user_id,
                         name: IBDetail.result.ib.name,
                         lastname: IBDetail.result.ib.lastname,
                    })
                    sub_rebate.forex.map((el, i) => this.setState({ ["forex" + i]: parseFloat(sub_rebate.forex[i]).toFixed(2) || 0.0 }));
                    sub_rebate.gold.map((el, i) => this.setState({ ["gold" + i]: parseFloat(sub_rebate.gold[i]).toFixed(2) || 0.0 }));
                    sub_rebate.crypto.map((el, i) => this.setState({ ["crypto" + i]: parseFloat(sub_rebate.crypto[i]).toFixed(2) || 0.0 }));
                    sub_rebate.index.map((el, i) => this.setState({ ["index" + i]: parseFloat(sub_rebate.index[i]).toFixed(2) || 0.0 }));
                    sub_rebate.oil.map((el, i) => this.setState({ ["oil" + i]: parseFloat(sub_rebate.oil[i]).toFixed(2) || 0.0 }));
                    this.setState({
                         name: IBDetail.result.ib.name + " " + IBDetail.result.ib.lastname,
                         dividend: IBDetail.result.ib.ib_percent,
                         dataSub,
                         nav_sub_ib,
                         loading: false,
                         manager_id: IBDetail.result.ib.manager_id,
                    });
                    this.setState({ loading: false });
                    this.getIbAccountType();
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: IBDetail.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     getNetwork = async () => {
          let { date_start, date_end } = this.state;
          let date_start_format = moment(date_start).format("YYYY-MM-DD");
          let date_end_format = moment(date_end).format("YYYY-MM-DD");
          try {
               this.setState({ loading: true });
               let network = await POST(GETIBNETWORK, { date_start: date_start_format, date_end: date_end_format, user_id: this.props.match.params.id });
               if (network.success) {
                    this.setState({ data_header_profile: network.result.filter((e) => Number(e.user_id) === Number(this.props.match.params.id)) })
                    if (network.result.length > 0) {
                         this.setState({ IB: network.result });
                         this.getCountNetwork(this.props.match.params.id);
                    } else {
                         this.setState({ network: network.result });
                    }

                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: network.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     onClickNetworkChild = async (user_id, name, lastname) => {

          const { date_end, date_start, nav_sub_ib, page } = this.state;
          let date_start_format = moment(date_start).format("YYYY-MM-DD");
          let date_end_format = moment(date_end).format("YYYY-MM-DD");

          try {
               this.setState({ loading: true });
               let network = await POST(GETIBALL, { date_start: date_start_format, date_end: date_end_format, user_id, page: 1 });
               if (network.success) {
                    nav_sub_ib.push({
                         user_id,
                         name,
                         lastname,
                    })
                    this.setState({ network: network.result.ib_data, IB: network.result.ib_data, count_page: network.result.count, user_id, page: 1 });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: network.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     onClickNetworkChildBack = async (user_id) => {

          const { date_end, date_start, nav_sub_ib, page } = this.state;
          let date_start_format = moment(date_start).format("YYYY-MM-DD");
          let date_end_format = moment(date_end).format("YYYY-MM-DD");

          try {
               this.setState({ loading: true });
               // let network = await POST(GETIBALL, { date_start: date_start_format, date_end: date_end_format, user_id });
               let network = await POST(GETIBALL, { date_start: date_start_format, date_end: date_end_format, user_id, page: 1 });
               if (network.success) {
                    const indexObj = nav_sub_ib.findIndex(el => (el.user_id === user_id))
                    this.setState({ nav_sub_ib: nav_sub_ib.slice(0, indexObj + 1) });
                    this.setState({ network: network.result.ib_data, IB: network.result.ib_data, count_page: network.result.count, user_id, page: 1 });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: network.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     getHeader = async () => {
          this.setState({ data_header: [] })
          let { date_start, date_end } = this.state;
          let date_start_format = moment(date_start).format("YYYY-MM-DD");
          let date_end_format = moment(date_end).format("YYYY-MM-DD");
          // console.log('dataheaer2')
          try {
               let amount = await POST(GETIBHEADER, { date_start: date_start_format, date_end: date_end_format, user_id: this.props.match.params.id });
               if (amount.success) {
                    this.setState({ data_header: amount.result })
               }
          } catch (error) {
               console.log(error)
          }
     }

     getCountNetwork = async (user_id, value) => {

          let { date_start, date_end, page } = this.state;
          let date_start_format = moment(date_start).format("YYYY-MM-DD");
          let date_end_format = moment(date_end).format("YYYY-MM-DD");

          try {
               this.setState({ loading: true });
               // let amount = await POST(GETIBALL, { date_start: date_start_format, date_end: date_end_format, user_id: user_id ? user_id : this.props.match.params.id });
               let amount = await POST(GETIBALL, { date_start: date_start_format, date_end: date_end_format, user_id: user_id ? user_id : this.props.match.params.id, page: value ? value : page });
               if (amount.success) {
                    this.setState({ network: amount.result.ib_data, count_page: amount.result.count })
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: amount.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               console.log('error', error)
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     // getCountNetwork = async (user_id) => {
     //      let { date_start, date_end } = this.state;
     //      let date_start_format = moment(date_start).format("YYYY-MM-DD");
     //      let date_end_format = moment(date_end).format("YYYY-MM-DD");
     //      // console.log(obj_data)

     //      try {
     //           this.setState({ loading: true, page: 1 });
     //           let amount = await POST(GETCOUNTNETWORKALL, { date_start: date_start_format, date_end: date_end_format, user_id: user_id ? user_id : this.props.match.params.id });
     //           if (amount.success) {
     //                let network = []
     //                amount.result.map(el => {
     //                     let data_net = el.data_net;
     //                     let team_deposit = data_net.map((e) => e.deposit).filter((e) => e.money !== null).reduce((prevValue, currentValue) => prevValue + currentValue.money, 0);
     //                     let team_withdraw = data_net.map((e) => e.withdraw).filter((el) => el.money !== null).reduce((prevValue, currentValue) => prevValue + currentValue.money, 0);
     //                     network.push({
     //                          name: el.name,
     //                          lastname: el.lastname,
     //                          email: el.email,
     //                          invite: el.invite,
     //                          invite_id: el.invite_id,
     //                          user_id: el.user_id,
     //                          count: el.count,
     //                          commission: el.commission,
     //                          myLot: el.myLot,
     //                          allLotTeam: el.allLotTeam,
     //                          team_deposit,
     //                          team_withdraw,
     //                     })
     //                })
     //                let IB = this.state.IB
     //                let op = network.map((e, i) => {
     //                     let temp = IB.find(element => element.user_id === e.user_id)
     //                     if (temp) {
     //                          e.deposit = temp.deposit
     //                          e.withdraw = temp.withdraw
     //                     }
     //                     return e;
     //                })
     //                this.setState({ network: op })
     //                this.setState({ loading: false });
     //           } else {
     //                this.setState({ loading: false });
     //                swal.fire({
     //                     title: "ไม่สำเร็จ", text: amount.message, icon: "warning",
     //                     // timer: 6000, 
     //                     showConfirmButton: true
     //                });
     //           }
     //      } catch (error) {
     //           console.log('error', error)
     //           this.setState({ loading: false });
     //           swal.fire({
     //                title: "ไม่สำเร็จ", text: error, icon: "warning",
     //                // timer: 6000, 
     //                showConfirmButton: true
     //           });
     //      }
     // };

     getIbSymbol = async () => {
          try {
               this.setState({ loading: true });
               let symbol = await GET(IBSYMBOL);
               if (symbol.success) {
                    this.setState({ symbol: symbol.result, loading: false });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               this.setState({ loading: false });
          }
     };

     getAccountType = async () => {
          try {
               let account = await POST(ACCOUNTTYPEAPI, {});
               // let account = await POST(ACCOUNTTYPEAPI, {user_id:props.userId});
               if (account.success) {
                    this.setState({ accountType: account.result });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               this.setState({ loading: false });
               console.log(`error`, error);
          }
     }

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     handleChangeTextRebate = (e, i, id, typeId, index) => {
          // console.log();

          let { rebate_body, rebate, dataTabel } = this.state;
          let name = e.target.name;
          // console.log("name", name);
          let value = Number(e.target.value);



          // rebate.find((el) => el.user_id === id).rebate.rebate[i][name] = value;
          // // console.log("test",rebate.find((el) => el.user_id === id).rebate.rebate);
          // console.log("rebate",rebate);
          // this.setState({ rebate });

          const newRoles = dataTabel.slice(0);
          newRoles[index] = value;
          this.setState({ dataTabel: newRoles });

          rebate.find((el) => el.user_id === id).rebate.rebate[i][name] = dataTabel;
          this.setState({ rebate });
          // console.log("rebate", rebate);

     };

     onClickAddRebate = (id) => {
          let { rebate } = this.state;
          rebate.find((el) => el.user_id === id).rebate.rebate.push({ forex: 0, gold: 0, crypto: 0, index: 0, oil: 0 });

          this.setState({ ...rebate, rebate: rebate });
     };

     onClickSaveRebate = async (data) => {
          // try {
          //      let rebate = JSON.stringify(data.rebate.rebate);
          //      let save_rebate = await POST(EDITREBATE, { user_id: this.props.match.params.id, sub_1_user_id: data.rebate.sub_1_user_id, rebate });
          //      if (save_rebate.success) {
          //           swal.fire({ title: "สำเร็จ", text: "แก้ไข Rebate สำเร็จ", icon: "success", timer: 1800, showConfirmButton: false });
          //      } else {
          //           swal.fire({ title: "ไม่สำเร็จ", text: save_rebate.message, icon: "warning", timer: 1800, showConfirmButton: false });
          //      }
          // } catch (error) {
          //      swal.fire({ title: "ไม่สำเร็จ", text: error, icon: "warning", timer: 1800, showConfirmButton: false });
          // }
     };

     onClickAddSymbol = () => {
          let { dataSub } = this.state;
          dataSub.push({
               forex: 0,
               gold: 0,
               crypto: 0,
               index: 0,
               oil: 0,
          });
          this.setState({ dataSub });
     };

     handleChangeTextSub = (e, i, name) => {
          let { dataSub } = this.state;
          if (name === "forex") {
               dataSub[i].forex = parseFloat(e.target.value).toFixed(2);
          } else if (name === "gold") {
               dataSub[i].gold = parseFloat(e.target.value).toFixed(2);
          } else if (name === "crypto") {
               dataSub[i].crypto = parseFloat(e.target.value).toFixed(2);
          } else if (name === "index") {
               dataSub[i].index = parseFloat(e.target.value).toFixed(2);
          } else if (name === "oil") {
               dataSub[i].oil = parseFloat(e.target.value).toFixed(2);
          }
          this.setState({ [e.target.name]: parseFloat(e.target.value).toFixed(2), dataSub });
     };
     onClickEditManager = () => {
          this.setState({ edit_disabled_manager: false });
     };
     onClickSaveManager = async () => {
          const { dividend } = this.state;
          if (dividend > 100 || dividend < 0) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: "กรุณากรอกข้อมูลให้ถูกต้อง", icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          } else {
               try {
                    this.setState({ loading: true });
                    let editManager = await POST(EDITMANAGERPERCENT, { ib_percent: dividend, user_id: this.props.match.params.id });
                    if (editManager.success) {
                         swal.fire({
                              title: "สำเร็จ", text: "แก้ไข Percent Manager สำเร็จ", icon: "success",
                              // timer: 6000, 
                              showConfirmButton: true
                         });
                         this.setState({ edit_disabled_manager: true, loading: false });
                    } else {
                         swal.fire({
                              title: "ไม่สำเร็จ", text: editManager.message, icon: "warning",
                              // timer: 6000, 
                              showConfirmButton: true
                         });
                         this.setState({ loading: false });
                    }
               } catch (error) {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: error, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
                    this.setState({ loading: false });
               }
          }
     };
     onClickEditIB = () => {
          this.setState({ edit_disabled_ib: false });
     };
     onClickSaveIB = async () => {
          const { dataSub, symbol } = this.state;
          let sub_rebate = { forex: [], gold: [], crypto: [], index: [], oil: [] };
          dataSub.map(
               (el, i) => (
                    sub_rebate.forex.push(el.forex), sub_rebate.gold.push(el.gold), sub_rebate.crypto.push(el.crypto), sub_rebate.index.push(el.index), sub_rebate.oil.push(el.oil)
               )
          );
          // await symbol.map(async (ele, i) => {
          //      if (ele.name === "forex") {
          //           let sum = 0;
          //           sub_rebate.forex.map((el) => (sum = sum + Number(el)));
          //           if (sum > ele.pay) {
          //                await this.setState({ checkForex: false, message_error: `${ele.name} ห้ามเกิน ${ele.pay}` });
          //           } else {
          //                await this.setState({ checkForex: true });
          //           }
          //      } else if (ele.name === "gold") {
          //           let sum = 0;
          //           sub_rebate.gold.map((el) => (sum = sum + Number(el)));
          //           if (sum > ele.pay) {
          //                await this.setState({ checkGold: false, message_error: `${ele.name} ห้ามเกิน ${ele.pay}` });
          //           } else {
          //                await this.setState({ checkGold: true });
          //           }
          //      } else if (ele.name === "crypto") {
          //           let sum = 0;
          //           sub_rebate.crypto.map((el) => (sum = sum + Number(el)));
          //           if (sum > ele.pay) {
          //                await this.setState({ checkCrypto: false, message_error: `${ele.name} ห้ามเกิน ${ele.pay}` });
          //           } else {
          //                await this.setState({ checkCrypto: true });
          //           }
          //      } else if (ele.name === "index") {
          //           let sum = 0;
          //           sub_rebate.index.map((el) => (sum = sum + Number(el)));
          //           if (sum > ele.pay) {
          //                await this.setState({ checkIndex: false, message_error: `${ele.name} ห้ามเกิน ${ele.pay}` });
          //           } else {
          //                await this.setState({ checkIndex: true });
          //           }
          //      } else if (ele.name === "oil") {
          //           let sum = 0;
          //           sub_rebate.oil.map((el) => (sum = sum + Number(el)));
          //           if (sum > ele.pay) {
          //                await this.setState({ checkOil: false, message_error: `${ele.name} ห้ามเกิน ${ele.pay}` });
          //           } else {
          //                await this.setState({ checkOil: true });
          //           }
          //      }
          // });
          this.postApiManageIB(sub_rebate);
     };

     postApiManageIB = async (sub_rebate) => {
          const { checkForex, checkGold, checkCrypto, checkIndex, checkOil, message_error } = this.state;
          // if (checkForex && checkGold && checkCrypto && checkIndex && checkOil) {
          try {
               this.setState({ loading: true });
               let editIbPercent = await POST(EDITIBPERCENT, { sub_rebate: JSON.stringify(sub_rebate), user_id: this.props.match.params.id });
               if (editIbPercent.success) {
                    swal.fire({
                         title: "สำเร็จ", text: "แก้ไข IB Percent สำเร็จ", icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
                    this.setState({ edit_disabled_ib: true, loading: false });
                    this.getIBDetail();
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: editIbPercent.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
          // } else {
          //      swal.fire({ title: "ไม่สำเร็จ", text: message_error, icon: "warning", timer: 1800, showConfirmButton: false });
          // }
     };

     getRebate = async () => {
          try {
               let rebate = await POST(GETREBATE, { user_id: this.props.match.params.id });
               if (rebate.success) {
                    const result = rebate.result;
                    this.setState({ rebate: result });
               }
          } catch (error) {
               console.log("error", error);
          }
     };

     getIbAccountType = async () => {
          try {
               let ib_accountype = await POST(GETIBACCOUNTTYPE, { manager_id: this.state.manager_id });
               if (ib_accountype.success) {
                    const result = ib_accountype.result;
                    const name_th = JSON.parse(result[0].account_type_name).th;
                    const name_en = JSON.parse(result[0].account_type_name).en;
                    const leverage = result[0].leverage;
                    const manager_name = result[0].manager_name;
                    this.setState({ ib_accountype: ib_accountype.result, name_th, name_en, leverage, manager_name });
               }
          } catch (error) {
               console.log("error", error);
          }
     };

     onClickEditAccount = async () => {
          const { name_th, name_en, leverage, manager_name, manager_id } = this.state;
          try {
               let edit_account = await POST(EDITIBACCOUNTTYPE, { name_th, name_en, leverage, manager_name, manager_id });
               if (edit_account.success) {
                    swal.fire({
                         title: "สำเร็จ", text: edit_account.result, icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               } else {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: edit_account.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     submitSearch = async (value) => {
          let { date_start, date_end, user_id, search_text, nav_sub_ib } = this.state;
          let date_start_format = moment(date_start).format("YYYY-MM-DD");
          let date_end_format = moment(date_end).format("YYYY-MM-DD");
          let obj = search_text ? { date_start: date_start_format, date_end: date_end_format, search_text, page: value ? value : 1 } : { date_start: date_start_format, date_end: date_end_format, user_id: user_id ? user_id : this.props.match.params.id, page: 1 }

          // console.log(obj);

          try {
               this.setState({ loading: true, page: value ? value : 1 });
               let getIB = await POST(search_text ? GETIBSEARCH : GETIBALL, obj);
               if (getIB.success) {
                    this.getHeader();
                    if (search_text) {
                         this.setState({ nav_sub_ib: nav_sub_ib.splice(0, 1) });
                    }
                    this.setState({ network: getIB.result.ib_data, IB: getIB.result.ib_data, count_page: getIB.result.count });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
          }
     };

     handleDateStartChange = (date) => {
          this.setState({ date_start: date });
     };

     handleDateEndChange = (date) => {
          this.setState({ date_end: date });
     };
     handleChangePage = (e, value) => {
          const { user_id, search_text } = this.state;
          this.setState({ page: value });
          if (search_text) {
               this.submitSearch(value);
          } else {
               this.getCountNetwork(user_id ? user_id : this.props.match.params.id, value);
          }
     };
     handleSearch = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };
     render() {
          const { page, IB, dividend_text, tap_value, edit_disabled_manager, edit_disabled_ib, name, dataSub, network, loading, symbol, ib_accountype, nav_sub_ib, count_network, data_header, user_id, data_header_profile, count_page } = this.state;
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    <Loading open={loading} />
                    <TableIB
                         name={name}
                         data_network={network}
                         IB={IB}
                         // data_network={network.filter((e) => Number(e.invite_id) === Number(user_id))}
                         data_header={data_header}
                         data_header_profile={data_header_profile}
                         nav_sub_ib={nav_sub_ib}
                         count_network={count_network}
                         // data_ib={network}
                         dataSub={dataSub}
                         state={this.state}
                         dividend_text={dividend_text}
                         handleChangeText={this.handleChangeText}
                         handleChangeTextRebate={this.handleChangeTextRebate}
                         handleChangeTextSub={this.handleChangeTextSub}
                         onClickSaveRebate={this.onClickSaveRebate}
                         onClickAddRebate={this.onClickAddRebate}
                         tap_value={tap_value}
                         setTap={(e) => this.setState({ tap_value: e })}
                         edit_disabled_manager={edit_disabled_manager}
                         edit_disabled_ib={edit_disabled_ib}
                         onClickEditManager={this.onClickEditManager}
                         onClickSaveManager={this.onClickSaveManager}
                         onClickEditIB={this.onClickEditIB}
                         onClickSaveIB={this.onClickSaveIB}
                         symbol={symbol}
                         onClickAddSymbol={this.onClickAddSymbol}
                         ib_accountype={ib_accountype}
                         onClickEditAccount={this.onClickEditAccount}
                         userId={this.props.match.params.id}
                         onClickNetworkChild={this.onClickNetworkChild}
                         onClickNetworkChildBack={this.onClickNetworkChildBack}
                         handleDateStartChange={this.handleDateStartChange}
                         handleDateEndChange={this.handleDateEndChange}
                         submitSearch={this.submitSearch}
                         page={page}
                         handleChangePage={this.handleChangePage}
                         count_page={count_page}
                         handleSearch={this.handleSearch}
                    />
               </Container>
          );
     }
}

export default withRouter(IBDetail);
