import { Button, Grid, Menu, MenuItem, Pagination, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
// import moment from "moment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import moment from "moment";
import { useHistory, withRouter } from "react-router-dom";

const GridTable = (props) => {
     const { language } = props;
     const classes = useStyles();
     const [anchorEl, setAnchorEl] = React.useState(null);
     const [id, setId] = React.useState(null);
     const [name, setName] = React.useState(null);
     const [lastname, setLastname] = React.useState(null);
     const [email, setEmail] = React.useState(null);
     const [remark, setRemark] = React.useState(null);
     const history = useHistory();
     const [account_count, setAccount_count] = React.useState(null);
     const [account_demo, setAccount_demo] = React.useState(null);
     const [credit, setCredit] = React.useState(0);

     const handleClick = (event, id, name, lastname, email, remark, account_count, account_demo, credit) => {
          setId(id);
          setName(name);
          setLastname(lastname);
          setEmail(email);
          setRemark(remark);
          setAnchorEl(event.currentTarget);
          setAccount_count(account_count);
          setAccount_demo(account_demo)
          setCredit(credit)
     };

     const handleClose = () => {
          setAnchorEl(null);
     };
     const openDetail = () => {
          history.push(`/profile_detail/${id}`);
     };
     const openReport = () => {
          history.push(`/report_trading/${id}`);
     };
     const openResetPassword = () => {
          history.push(`/reset_password/${id}`);
     };
     const openBonusSetting = () => {
          history.push(`/bonus_setting/${id}`);
     };

     //-------------------------------------------------//
     return (
          <Grid className="table-btn">
               <TableContainer>
                    <Table className={classes.table}>
                         <TableHead>
                              <TableRow>
                                   {props.head.map((el, i) => {
                                        return (
                                             <TableCell key={i} align={el.align || "left"}>
                                                  <Typography noWrap>{el.label}</Typography>
                                             </TableCell>
                                        );
                                   })}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {props.body.map((el, i) => {
                                   return (
                                        <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                             {/* <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.name || "-"}
                                                  </Typography>
                                             </TableCell> */}
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {`${el.user_id}` || "-"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {`${el.name} ${el.lastname}` || "-"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {(Math.round(Number(el.wallet) * 100) / 100) || "0.00"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {(Math.round(Number(el.balance) * 100) / 100) || "0.00"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {(Math.round(Number(el.ib_wallet) * 100) / 100) || "0.00"}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.phone}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.email}
                                                  </Typography>
                                             </TableCell>
                                             {props.role !== 'sale' &&
                                                  <TableCell>
                                                       <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                            {el.admin_name} {el.admin_lastname}
                                                       </Typography>
                                                  </TableCell>
                                             }
                                             {/* <TableCell  align="center">
                                                  {el.verify_email === 1 ? (
                                                       <Typography noWrap className={classes.fontActive} sx={{ backgroundColor: "#82C729" }}>
                                                            Success
                                                       </Typography>
                                                  ) : el.verify_email === 2 ? (
                                                       <Typography noWrap className={classes.fontActive} sx={{ backgroundColor: "#FF0000" }}>
                                                            Unverify
                                                       </Typography>
                                                  ) : (
                                                       "-"
                                                  )}
                                             </TableCell> */}
                                             {/* <TableCell>
                                                  <Link
                                                       component="button"
                                                       sx={{ fontSize: 16, color: "#184B8E" }}
                                                       onClick={() => {
                                                            // openEdit(i, ind);
                                                       }}
                                                  >
                                                       Download
                                                  </Link>
                                             </TableCell> */}
                                             {/* <TableCell>
                                                  <Link
                                                       component="button"
                                                       sx={{ fontSize: 16, color: "#184B8E" }}
                                                       onClick={() => {
                                                            // openDetail(el.id, i);
                                                       }}
                                                  >
                                                       View
                                                  </Link>
                                             </TableCell> */}
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {moment(el.created_at).format("DD/MM/YYYY")}
                                                  </Typography>
                                             </TableCell>
                                             {props.role === 'admin' &&
                                                  <TableCell align="center">
                                                       <Switch
                                                            checked={el.enable_withdraw}
                                                            onChange={() => props.handleChangeEnableWithdraw(el.user_id, el.enable_withdraw)}
                                                       />
                                                  </TableCell>
                                             }
                                             <TableCell align="center">
                                                  {
                                                       el.user_status === 0 ? (
                                                            <Typography noWrap className={classes.fontActive} sx={{ backgroundColor: "#FF0000" }}>
                                                                 Ban
                                                            </Typography>
                                                       ) : el.verify_doc === 1 ? (
                                                            <Typography noWrap className={classes.fontActive} sx={{ backgroundColor: "#82C729" }}>
                                                                 Active
                                                            </Typography>
                                                       ) : el.verify_doc === 2 ? (
                                                            <Typography noWrap className={classes.fontActive} sx={{ backgroundColor: "#FF0000" }}>
                                                                 Unverify
                                                            </Typography>
                                                       ) : el.verify_doc === 3 ? (
                                                            <Typography noWrap className={classes.fontActive} sx={{ backgroundColor: "red" }}>
                                                                 Unsuccess
                                                            </Typography>
                                                       ) : el.verify_doc === 4 ? (
                                                            <Typography noWrap className={classes.fontActive} sx={{ backgroundColor: "#002BB7" }}>
                                                                 Pending
                                                            </Typography>
                                                       ) : ("-")
                                                  }
                                             </TableCell>
                                             <TableCell align="center">
                                                  <Typography noWrap sx={{ fontSize: 20, fontWeight: 600, color: "#184B8E" }}>
                                                       <Button
                                                            onClick={(event) => handleClick(event, el.user_id, el.name, el.lastname, el.email, el.remark, el.account_count, el.account_demo, el.credit)}
                                                            className={classes.button}
                                                       >
                                                            <MoreHorizIcon />
                                                       </Button>
                                                       <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                                                            <MenuItem
                                                                 onClick={() => {
                                                                      openDetail();
                                                                      handleClose();
                                                                 }}
                                                            >
                                                                 {language === "th" ? "ดูข้อมูลส่วนตัว" : "View Profile"}
                                                            </MenuItem>
                                                            <MenuItem
                                                                 onClick={() => {
                                                                      openReport();
                                                                      handleClose();
                                                                 }}
                                                            >
                                                                 {language === "th" ? "ดูรายงานการเทรด" : "Trading Report"}
                                                            </MenuItem>
                                                            {/* {props.role !== "normal" && ( */}
                                                            <MenuItem
                                                                 onClick={() => {
                                                                      props.OnclickOpenRemark(id, name, lastname, email, remark);
                                                                      handleClose();
                                                                 }}
                                                            >
                                                                 {language === "th" ? "เพิ่ม Remark ลูกค้า" : "Add Remark user"}
                                                            </MenuItem>
                                                            {/* )} */}
                                                            <MenuItem
                                                                 onClick={() => {
                                                                      props.OnclickOpenLimitAccountModal(id, account_count);
                                                                      handleClose();
                                                                 }}
                                                            >
                                                                 {language === "th" ? "แก้ไขจำนวนบัญชี" : "Manage Limit Account"}
                                                            </MenuItem>
                                                            <MenuItem
                                                                 onClick={() => {
                                                                      props.OnclickOpenAccountDemoModal(id, account_demo);
                                                                      handleClose();
                                                                 }}
                                                            >
                                                                 {language === "th" ? "บัญชี Demo" : "Account Demo"}
                                                            </MenuItem>
                                                            <MenuItem
                                                                 onClick={() => {
                                                                      handleClose();
                                                                      openResetPassword();
                                                                 }}>
                                                                 {language === "th" ? "รีเซ็ทรหัสผ่าน" : "Reset Password"}
                                                            </MenuItem>
                                                            {props.role !== "sale" && (
                                                                 <MenuItem
                                                                      onClick={() => {
                                                                           handleClose();
                                                                           openBonusSetting();
                                                                      }}>
                                                                      {language === "th" ? "ตั้งค่าโบนัส" : "Bonus Setting"}
                                                                 </MenuItem>
                                                            )}
                                                            {props.role !== 'sale' &&
                                                                 <MenuItem
                                                                      onClick={() => {
                                                                           props.handleDeleteUser(id, name, lastname);
                                                                           handleClose();
                                                                      }}
                                                                 >
                                                                      {language === "th" ? "ลบ" : "Delete"}
                                                                 </MenuItem>
                                                            }
                                                            {props.role === "admin" && (
                                                                 <MenuItem
                                                                      onClick={() => {
                                                                           props.OnclickOpenCashbackModal(id, name, lastname);
                                                                           handleClose();
                                                                      }}
                                                                 >
                                                                      Cashback
                                                                 </MenuItem>
                                                            )}
                                                            {/* <MenuItem
                                                                 onClick={() => {
                                                                      props.OnclickOpenCreditModal(id, credit, name, lastname);
                                                                      handleClose();
                                                                 }}
                                                            >
                                                                 ปรับเครดิต
                                                            </MenuItem> */}
                                                            {props.role !== 'sale' &&
                                                                 <MenuItem
                                                                      onClick={() => {
                                                                           props.handleBanUser(id, name, lastname);
                                                                           handleClose();
                                                                      }}
                                                                 >
                                                                      {language === "th" ? "Ban User" : "Ban User"}
                                                                 </MenuItem>
                                                            }
                                                       </Menu>
                                                  </Typography>
                                             </TableCell>
                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               <Grid container justifyContent="center" mt={5}>
                    {props.body.length === 0 && (
                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                              {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                         </Typography>
                    )}
                    {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
     button: {
          "&:hover": {
               backgroundColor: "none",
          },
     },
     fontActive: {
          fontSize: 15,
          color: "#fff",
          padding: 8,
          borderRadius: 10,
     },
});
export default withRouter(GridTable);
