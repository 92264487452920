import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography, Pagination, Link, Button, Stack } from "@material-ui/core";
// import moment from "moment";
import { withRouter } from "react-router-dom";

const GridTableListCampaign = (props) => {
     const classes = useStyles();
     //-------------------------------------------------//
     return (
          <Grid className="table-btn">
               <TableContainer>
                    <Table className={classes.table}>
                         <TableHead>
                              <TableRow>
                                   {props.head.map((el, i) => {
                                        return (
                                             <TableCell key={i} align={el.align || "left"}>
                                                  <Typography noWrap>{el.label}</Typography>
                                             </TableCell>
                                        );
                                   })}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {props.body.map((el, i) => {
                                   return (
                                        <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                             <TableCell>
                                                  {el.data.map((e, ind) =>
                                                       <span key={ind + 1}  >
                                                            {e.name} {e.lastname} &nbsp;{el.data.length - (ind + 1) > 0 && `>`}&nbsp;
                                                       </span>
                                                  )}
                                             </TableCell>
                                             {props.role === "admin" ?
                                                  <TableCell align="center">
                                                       <Stack direction="row" spacing={3} width="100%" display="flex" justifyContent="center">
                                                            <Button variant="outlined" onClick={() => props.handleEditCampaign(el.user_id, el.header, el.campaign)} disabled={props.enable_add}>
                                                                 Edit
                                                            </Button>
                                                            <Button variant="outlined" onClick={() => props.handleDeleteCampaign(el.user_id, el.header, el.campaign)} disabled={props.enable_add}>
                                                                 Delete
                                                            </Button>
                                                       </Stack>
                                                  </TableCell>
                                                  :
                                                  <TableCell align="center">
                                                       <Button variant="outlined" onClick={() => props.handleViewCampaign(el.user_id, el.header, el.campaign)}>
                                                            View
                                                       </Button>
                                                  </TableCell>
                                             }
                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               <Grid container justifyContent="center" mt={5}>
                    {props.body.length === 0 && (
                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                              {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                         </Typography>
                    )}
                    {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
});
export default withRouter(GridTableListCampaign);
